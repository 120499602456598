// This file was coppied from an OLD version of the html/css structure
// This should ALL be cleaned up

@import 'a3_variables.less';
@import 'dark_variables.less';

.document-library { position: relative; }

/* filter header row */
.document-library .above .filter { float: left; margin-left: 10px; position: relative; }
.document-library .above .filter:first-child {margin-left:0;}
.document-library .above .filter input.search { height:36px; border-top:1px solid @white; margin-right:0; width:300px; border-top-right-radius:0; border-bottom-right-radius: 0; box-shadow: 0 1px 1px rgba(0,0,0,.18);}
.document-library .above .filter input + button { margin-left:0; border-top-left-radius:0; border-bottom-left-radius:0;}

.document-thumbnail { border: 2px solid @tabGray; padding: 1px; position: absolute; top: 0; bottom: 0; left: 0; right: 0; background-color: @white; margin-top: 10px; }
.document-title { position: absolute; top: 5px; left: 5%; width: 90%; color: @white; background-color: @charcoal; opacity: 0.9; padding: 5px; text-align: center; overflow: hidden; font-size: 11px; }
.document-thumbnail img { width: 100%; max-height: 100%; }

/* I USE THESE TWO CLASSES TO KEEP THE RATIO (HEIGHT VS WIDTH) CORRECT AS THE WINDOW SCALES DOWN */
.document-library .ratio-keeper { width: 100%; position: relative; display: inline-block; }
.document-library .ratio-keeper:after { padding-bottom: 110%; display: block; content: ''; z-index: 1; }

.document-footer { border-top: 1px solid lighten(@charcoal, 50%); color: @primaryFontColor; padding: 5px 10px; background-color: @white; position: absolute; bottom: 1px; left: 1px; right: 1px; z-index: 10; }
//.document-footer > div.dropdown { float: right; }
//.document-footer > div.dropdown > a { color: @white; text-decoration: none; }
.document-footer .document-category { display: inline-block; font-size: 11px; line-height: 1.2em; vertical-align: middle; }
.document-footer i.mapped { line-height: 22px; position: absolute; right: 10px; }
.document-footer i.review { line-height: 22px; position: absolute; right: 30px; }

//.document-rater { float: left; color: @orange; }
//.document-rater .fa-star-empty { opacity: 0.3; }
//.document-library-thumbnail-view .dropzone { height: 100%;}
.document-library .dropzone { margin-bottom: 10px; position: relative; }
.document-library .dropzone .progress { bottom: -12px; }
.document-library .initial-state { margin-top: 20px; }

/* hover effect */
.document-thumbnail:hover,
.open .document-thumbnail { border-color: @orange; }
.document-thumbnail:hover { color: @orange; }

.ng-pageslide img {border: 1px solid #888; margin: 20px 0;}
.ng-pageslide .detail-info .detail-label {display:block; font-size:small; color:#888; }
.ng-pageslide .detail-info .detail-label ~ .detail-label { margin-top:10px; }

.document-library .dropdown.details .document-thumbnail { border-color: @orange; box-shadow:0px 0px 20px #555;}
.document-library .dropdown.details .document-thumbnail { border-color: @orange; box-shadow:0px 0px 20px #555;}
.document-library table tr.details { border: 1px solid orange; }

@media (min-width: 992px) and (max-width: 1588px) {
    .document-library .document-library-thumbnail-view .col-md-2:nth-of-type(6n+1) .dropdown-menu { left: 0; right: auto; }
}
@media (min-width: 992px) {
  .document-library .ratio-keeper-md { width: 100%; position: relative; display: inline-block; }
  .document-library .ratio-keeper-md:after { padding-bottom: 110%; display: block; content: ''; }
  //.document-library .dropzone-wrapper { position: absolute; top: 0; right: 0; bottom: 0; left: 0; display: inline-block; padding-bottom: 10px; }
}
@media (min-width: 768px) and (max-width: 991px) {
  //.document-library-thumbnail-view .dropzone { height: 60px; margin-bottom: 15px; }
}
@media (max-width: 767px) {
  //.document-library-thumbnail-view .dropzone { height: 60px; margin-bottom: 15px; }
}
@media (max-width: 480px) {
  //.document-library-thumbnail-view .dropzone { height: 60px; margin-bottom: 15px; }
}

body .themed.dark {
  .document-footer .document-category { 
    color: @dark_gray2;
  }
}
@import 'a3_variables.less';
@import 'dark_buttons.less';

body .themed.dark {
    .top-menu { 
        border-bottom: 1px solid @dark_topMenuBorderColor; 
        background:@dark_topMenuBackgroundColor; 
    }
    
    .top-menu .navbar-left li button { .dark-white-button }
    .top-menu .navbar-left li button:hover { .dark-white-button-hover }

    .top-menu .global-search::before { 
        background-color: @dark_inputBackground; 
        color: @dark_inputText; 
    }
    
    body .top-menu form.global-search .global-search-input input[type=text] {
        background-color: transparent; 
        color: @dark_inputText; 
        border-color: @dark_inputBorderColor; 
    }

    .top-menu .global-search button.search-button {
        border-color: @dark_inputBorderColor; 
        background-color: transparent;
    }
    
    .top-menu .global-search .search-results { 
        background-color: @dark_primaryBackgroundColor; 
        color: @dark_primaryFontColor; 
        box-shadow: @dark_primaryBoxShadow;
    }
    
    .top-menu .global-search .search-results .results-category { 
        border-top-color: @dark_inputBorderColor; 
    }
    
    .top-menu .global-search .search-results .results-category li.title { 
        color: @dark_orange; 
    }
    
    .top-menu .global-search .search-results .results-category li:not(.title) { 
        border-right-color: transparent; 
        color: @dark_primaryFontColor; 
    }
    
    .top-menu .global-search .search-results .results-category li:not(.title):hover { 
        background: @dark_gray2; 
        border-color: @dark_orange; 
    }
    
    .search-overlay { 
        background: @dark_primaryBackgroundColor; 
        opacity: .5; 
    }
    
    .navbar-right > li > a > i { 
        background: @dark_orange; 
        color: #fff; 
    }

    .top-menu .toggle-collapse { 
        background-color: @dark_toggleCollapseBackgroundColor;
        border-color: @dark_toggleCollapseFontFolor;
        top: 17px;
        left: -7px;
    }

    .navbar-right .notifications > a > i { 
        background: @dark_secondaryBackgroundColor; 
        color: @dark_primaryFontColor; 
    }
    
    .navbar-right .notifications > a > i { 
        color: @dark_primaryFontColor; 
    }

    .notifications .notification {
        background-color: transparent;
    }

    .notifications .notification:hover {
        background-color: @dark_gray3;
    }

    .notifications .dropdown-menu>header {
        background-color: transparent;
    }

    .notifications .notification .notification-actions button {
        color: @dark_accent1;
    }
    
    .notifications .notification .notification-actions button:hover {
        color: @dark_orange;
    }
}
@import 'a3_variables.less';

.bond-detail {
    .bond-summary { min-height: 135px; margin-bottom: 10px}
    .bond-summary .bond-type { font-size: 1.5em; }
    .bond-summary .bond-type a { font-size: .5em; }

    .bond-summary sb.bond-number { font-size: 1.2em; }

    .bond-summary .right-content { text-align: right; position: relative; }
    .bond-summary .right-content .actions { margin-top: 5px; }
    .bond-summary .right-content .actions button { margin-left:5px; }

    .tab-content iframe {min-height:900px; width:100%;}

    .bond-summary .right-content .total-value { display:inline-block; min-width:75px; text-align:right; }

    .initial-state { padding: 5px; }
    .initial-state .initial-state-body { max-width: 100%; }
}

.carrier {margin-top:10px;}
.carrier img { width: 40px; height:40px; float: left; margin-right: 10px; padding: 3px; background: white; border: 1px solid #ccc;}
.carrier .writing-company {font-size: .8em;}

.obligee-drop-down-name { font-size: 1.10em; border-top: 1px solid @gray}

@import "a3_variables.less";
@import "dark_variables.less";

body .themed.dark {
    #footer { 
        border-top: 1px solid @dark_footerBorderColor; 
        border-left:none; 
        background: @dark_secondaryBackgroundColor; 
        box-shadow: @dark_footerBoxShadow;
    }
    .footer-nav li a { color: @dark_primaryFontColor; }
    .footer-nav li a:hover { color: @dark_orange; text-decoration:none; }

    .footer-logo, .footer-logo:active, .footer-logo:visited, .footer-logo:hover { color: @dark_primaryFontColor }
}
@import 'a3_variables.less';
@import 'a3_buttons.less';
@import 'silver_variables.less';

body .themed.silver {
        section { background-color:#F6F7F8; border-color: #ebebeb; }

        section > header { border-bottom-color: #ebebeb; color:#333; }

        section.flag {border-color: red;}

        section.above { background-color:transparent; border-color:transparent; }

        /* color variants */
        section.dark { background-color: @tabGray; }

        .release-menu  { background-color: #fff; }

        .release-menu li { border-bottom-color: @translucentBorder; }

        .release-menu li:hover  { background-color:@gray; }
        .release-menu ul li.on { background-color:@gray; border-right:6px solid @orange; }
}
@import 'a3_variables.less';

body .themed.silver {
    body { font-family:@mainFont; font-size:@mainFontSize; }

    header .small:not(button) { font-size: 11px; font-weight: normal; line-height: 15px; text-transform: uppercase; }

    .green { color:@green; }
    .orange {color:@orange; }
    .red {color:@warningOrange; }
    .light-gray {color: @gray; }
    .gray {color: @darkGray; }

    .highlightedText { font-weight: bolder; }

    a { color: #627789; }
    a:hover {color:@orange; text-decoration:none; }

    section h1 {}
    section h2 {}
    section h3 {}
    section h4 {}
    section h5 { color:@orange; font-weight:normal; }
    section h6 { color:@orange; }

    .badge {background-color: #709cc1; color:#fff; font-weight:normal; border-radius:2px;}
    .badge.red { background-color: @red; color: @white; }

    span.new-badge { 
        border-radius:4px; 
        background:@orange; 
        color:#fff; 
    }
    
    span.fix-badge { 
        border-radius:4px; 
        background:@charcoal; 
        color:#fff; 
    }

    .dropdown .dropdown-menu li:not(.button-row), 
    .dropdown .dropdown-menu li:not(.button-row) a:not(.link), 
    .dropdown .dropdown-menu li:not(.button-row) button,
    .dropdown .dropdown-menu li:not(.button-row) > i, 
    .dropdown .dropdown-menu li:not(.button-row) > i {
        color: #666; 
        background-color: transparent;  
        border-top:none; 
        border-left:none; 
        border-bottom:none; 
        border-color: transparent;
    }
    
    .dropdown .dropdown-menu li:not(.button-row) a:hover, 
    .dropdown .dropdown-menu li:not(.button-row) button:hover {
        color: @orange; 
        border-color: @orange; 
    }

    .dropdown .dropdown-menu .divider {
        background-color: #E5E5E5; 
    }

    .document-title { 
        background-color: #304967; 
    }
}
@import 'a3_variables.less';
@import 'silver_buttons.less';

body .themed.silver {
    .top-menu { border-bottom-color: #ebebeb; background:@white; }
    
    .top-menu .navbar-left li button { .silver-white-button }
    .top-menu .navbar-left li button:hover { .silver-white-button-hover }
   
    .top-menu .global-search button.search-button {
        border-color: #d0d3d5;
    }

    .top-menu .global-search::before { color: @darkGray; }
    .top-menu .global-search .search-results .results-category { border-top-color: @tabGray; }
    .top-menu .global-search .search-results .results-category li.title { color: @lightFontColor; }
    .top-menu .global-search .search-results .results-category li:not(.title) { color: @primaryFontColor;  }
    .top-menu .global-search .search-results .results-category li:not(.title):hover { background: @gray; border-color: @orange; }
    .search-overlay { background:rgba(51,60,72,0.7); }
    .navbar-right > li > a > i { background:@orange; color:#fff; }

    .top-menu .toggle-collapse { background-color: #f5f5f5; border-right: 1px solid #ebebeb; border-bottom: 1px solid #ebebeb;  }

    .navbar-right .notifications > a > i { background: transparent; color: #627789; }
    .navbar-right .notifications > a > i { color: #627789; }
}
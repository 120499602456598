@import 'a3_variables.less';

.payment-history-table { font-size: x-small; }

.payment-history-table-year {color:@charcoal; margin-right:10px; margin-left:2px; text-align:left;}

.payment-history-table tr td { width: 7.69%; text-align:center; padding: 3px;}
.payment-history-table td span { padding:3px; font-weight: bold; }
.payment-history-na {}
.payment-history-two { background-color:yellow; }
.payment-history-three { background-color:orange;color: white; }
.payment-history-four { background-color:orangered;color: white; }
.payment-history-greater-than-four { background-color:red; }
.payment-history-collection { background-color:red; color: white;}
.payment-history-thirteen { background-color:black;color:white; }
.payment-history-repossession { background-color:red;color: white;}
.payment-history-charge-off { background-color:yellow;}
.payment-history-ok { background-color:green;color: white;}

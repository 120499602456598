@import 'a3_variables.less';

/* text inputs &*/
input[type=text],       input[type=email],  input[type=url],    input[type=number],
input[type=password],   textarea,           select,             .ui-select-match
{ 
    width:100%; 
    padding:3px 10px 3px 10px; 
    font-size:12px; 
    border-width:1px; 
    border-style: solid; 
    border-radius:0; 
    box-sizing:border-box; 
}

/* placeholders */
textarea::-webkit-input-placeholder, input::-webkit-input-placeholder { /* Chrome/Opera/Safari */ color: #ddd; }
textarea::-moz-placeholder, input::-moz-placeholder { /* Firefox 19+ */ color: #ddd; }
textarea:-ms-input-placeholder, input:-ms-input-placeholder { /* IE 10+ */ color: #ddd;}
textarea:-moz-placeholder, input:-moz-placeholder { /* Firefox 18- */ color: #ddd; }

.search textarea::-webkit-input-placeholder, .search input::-webkit-input-placeholder { /* Chrome/Opera/Safari */ color: #999; }
.search textarea::-moz-placeholder, .search input::-moz-placeholder { /* Firefox 19+ */ color: #999; }
.search textarea:-ms-input-placeholder, .search input:-ms-input-placeholder { /* IE 10+ */ color: #999;}
.search textarea:-moz-placeholder, .search input:-moz-placeholder { /* Firefox 18- */ color: #999; }

body .main-view .a-container .a-box input[type=email]::-webkit-input-placeholder { /* Chrome/Opera/Safari */ color: #999; }
body .main-view .a-container .a-box input[type=email]::-moz-placeholder { /* Firefox 19+ */ color: #999; }
body .main-view .a-container .a-box input[type=email]:-ms-input-placeholder { /* IE 10+ */ color: #999;}
body .main-view .a-container .a-box input[type=email]:-moz-placeholder { /* Firefox 18- */ color: #999; }

body .main-view .a-container .a-box input[type=password]::-webkit-input-placeholder { /* Chrome/Opera/Safari */ color: #999; }
body .main-view .a-container .a-box input[type=password]::-moz-placeholder { /* Firefox 19+ */ color: #999; }
body .main-view .a-container .a-box input[type=password]:-ms-input-placeholder { /* IE 10+ */ color: #999;}
body .main-view .a-container .a-box input[type=password]:-moz-placeholder { /* Firefox 18- */ color: #999; }

/* textarea */
textarea { 
    display: block; 
    height:auto; 
    line-height:13px; 
    min-height:50px; 
}

/* input outline */
input:not([type=button]):not([type=radio]):not([type=checkbox]):not([type=submit]):focus, 
textarea:focus, 
select:focus { 
    outline:0; 
    border-width:1px; 
    border-style: solid; 
}

input:disabled, 
select:disabled, 
select:disabled, 
textarea:disabled { 
    font-style: italic; 
    opacity:.7; 
    pointer-events: none; 
}

/* selects */
.ui-select-match { overflow: hidden; }
.ui-select-match span { white-space: nowrap; }

/* small */
.small input:not([type=checkbox]):not([type=radio]),
.small select,
.small .ui-select-match { 
    height: 26px; 
    padding-bottom: 2px; 
    padding-top: 2px; 
}

.field-wrapper input[aa-field-group-strategy=date],
.field-wrapper input[aa-field-group-strategy=dateString] {
    min-width: 85px;
}

/* field-wrapper */
.field-wrapper { 
    position:relative; 
    min-height:25px; 
    margin-top:25px; 
}

.field-wrapper div.label { 
    position:absolute; 
    top:-15px; 
    left:0px; 
    font-size:11px; 
    font-weight: normal; 
    line-height: 12px; 
    pointer-events: none; 
    padding:0; 
}

.field-wrapper div.label a {
    pointer-events: all; 
}

.field-wrapper[class*='col-'] div.label { left: 15px; }
.field-wrapper .validation-indicator { font-size: 12px; }
.field-wrapper input:not([type=checkbox]):not([type=radio]), .field-wrapper select { 
    -webkit-appearance: none; 
    -moz-appearance: none; 
    appearance: none; 
}

.field-wrapper .ui-select-container .ui-select-match .caret { display: none; }
.field-wrapper label + label { margin-left:10px; }

.field-wrapper.no-label { margin-top: 10px; }

/* empty-input */
.empty-input:not(.search) input:not(:focus),
.empty-input select:not(:focus),
.empty-input textarea:not(:focus) { box-shadow: none; }

// disabled inputs
.field-wrapper.disabled { opacity: 1; }
.field-wrapper.empty-input.select.disabled div.label { font-style: italic; }
.field-wrapper.empty-input .validation-indicator { font-size: inherit; }

/* labels */
label {
    margin:0; 
    font-weight:normal; 
}

.inline-label {
    line-height:25px; 
    margin-top:10px; 
    font-size:12px;
}

/* checkboxes & radios */
.radio, 
.checkbox, 
.radio + .radio, 
.checkbox + .checkbox { 
    padding-left: 0; 
    margin-top:10px;  
}

.radio[class*="col-"], 
.checkbox[class*="col-"] { 
    padding-left: 15px; 
    padding-right: 15px;
}

.radio-option input[type="radio"], 
input[type="checbox"]
{
    margin-left: 0;
    margin-right: 3px;
}

.radio label, .checkbox label { font-weight:bold; }

.radio input[type="radio"], 
.radio-inline input[type="radio"], 
.checkbox input[type="checkbox"], 
.checkbox-inline input[type="checkbox"] { 
    margin-left:0; 
    margin-right:5px;
    margin-top: 1px;
}

.radio-option + .radio-option {
    margin-left: 10px;
}

select.width-auto {
    width: auto;
}

.field-wrapper.fixed-width-xs input,
.field-wrapper.fixed-width-xs select,
.field-wrapper.fixed-width-xs textarea,
.fixed-width-xs .field-wrapper .ui-select-bootstrap,
.fixed-width-xs .field-wrapper .ui-select-search,
select.fixed-width-xs {
    width: 75px;
}
.field-wrapper.fixed-width-sm input,
.field-wrapper.fixed-width-sm select,
.field-wrapper.fixed-width-sm textarea,
.fixed-width-sm .field-wrapper .ui-select-bootstrap,
.fixed-width-sm .field-wrapper .ui-select-search,
select.fixed-width-sm {
    width: 100px;
}

.field-wrapper.fixed-width-md input,
.field-wrapper.fixed-width-md select,
.field-wrapper.fixed-width-md textarea,
.fixed-width-md .field-wrapper .ui-select-bootstrap,
.fixed-width-md .field-wrapper .ui-select-search,
select.fixed-width-md {
    width: 200px;    
}

.field-wrapper.fixed-width-lg input,
.field-wrapper.fixed-width-lg select,
.field-wrapper.fixed-width-lg textarea,
.fixed-width-lg .field-wrapper .ui-select-bootstrap,
.fixed-width-lg .field-wrapper .ui-select-search,
select.fixed-width-lg {
    width: 300px;    
}

.field-wrapper.checkbox.v-center {
    display: flex;
}

.field-wrapper.checkbox.v-center > label {
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
}

.field-wrapper.checkbox.v-center > label > input,
.field-wrapper.checkbox.v-center > label > span {
    margin-top:auto;
    margin-bottom: auto;
}

/* validation */
// these are the classes for standard agnular validation and angular agility validation
// .ng-invalid - angular adds when not valid field
// .ng-dirty - angular adds when field has been changed
// .aa-had-focus - angular agility adds on blur
// .aa-invalid-attempt - angular agility adds when form submitted but was invalid
// had focus && invalid                         field dirty && invalid                      form was submitted && invalid
input[type=text].ng-invalid.aa-had-focus, input[type=text].ng-invalid.ng-dirty, input[type=text].ng-invalid.aa-invalid-attempt,
input[type=email].ng-invalid.aa-had-focus, input[type=email].ng-invalid.ng-dirty, input[type=email].ng-invalid.aa-invalid-attempt,
input[type=url].ng-invalid.aa-had-focus, input[type=url].ng-invalid.ng-dirty, input[type=url].ng-invalid.aa-invalid-attempt,
input[type=number].ng-invalid.aa-had-focus, input[type=number].ng-invalid.ng-dirty, input[type=number].ng-invalid.aa-invalid-attempt,
input[type=password].ng-invalid.aa-had-focus, input[type=password].ng-invalid.ng-dirty, input[type=password].ng-invalid.aa-invalid-attempt,
textarea.ng-invalid.aa-had-focus, textarea.ng-invalid.ng-dirty, textarea.ng-invalid.aa-invalid-attempt,
select.ng-invalid.aa-had-focus, select.ng-invalid.ng-dirty, select.ng-invalid.aa-invalid-attempt,
.ui-select-match.ng-invalid.aa-had-focus, .ui-select-match.ng-invalid.ng-dirty, .ui-select-match.ng-invalid.aa-invalid-attempt {
    border-width: 1px;
    border-style: solid;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.18);
}

.aa-notify-close .fa-circle { 
    display: none;
} 

.validation-errors { 
    position: absolute; 
    z-index: 1; 
    width: 100%; 
    bottom: 14px; 
    right: 3px; 
}

.validation-error { 
    text-align:right; 
    bottom:-13px; 
    right:0px; 
    pointer-events:none; 
    position:absolute; 
    font-size: 70%; 
}

/* validation alert notification */
.validation-notification { 
    position: fixed; 
    top: 10px; 
    right: 10px; 
    width: 500px; 
    z-index: 10000;
}

.validation-notification .alert-danger {
    border-width:2px; 
    border-style:solid; 
    box-shadow: 2px 2px 2px @charcoal; 
}

.validation-notification ul { 
    padding: 0 20px; 
    margin-top: 5px;
}

.validation-notification ul li { }
.validation-notification ul li > a { margin-left: 10px; }
.validation-notification .aa-notify-close { 
    position:absolute; 
    top:0; 
    right:0; 
}

.inline-label-strategy { 
    display: flex;
}
.inline-label-strategy label {
    margin-top: auto;
    margin-bottom: auto;
}

.inline-label-strategy .validation-indicator {
    padding-top: 5px;
}

.inline-label-strategy :last-child {
    margin-left: 10px;
}

.field-wrapper.checkbox.top-margin {margin-top:10px;}
.field-wrapper.checkbox.top-margin-big {margin-top:20px;}
.field-wrapper.checkbox.inline { margin-top: 25px; margin-bottom: 0; padding-left: 0;}
.field-wrapper.checkbox .validation-indicator { display:none !important; }
.field-wrapper.checkbox .validation-error { top:-10px; bottom:initial; left: 0; right:initial; }
.field-wrapper.checkbox label { font-weight: normal; }

/* search boxes */
.search > div > input:not([type=button]):not([type=submit]) { border-radius: 2px; height: auto; font-size: 13px; padding: 4px 10px 5px 10px; }
.search > div > i.fa-search { position: absolute; top: 1px; right: 0; height: 27px; border-left: 1px solid; padding: 6px 14px; }
*[class*="col-"].search > div > i.fa-search { margin-right: 15px; }
.search .dropdown { position: absolute; right: 62px; top: ~'calc(50% - 12px)'; height: 24px;}
.search .dropdown button { border: none; box-shadow: none; font-weight: normal; background: transparent; }

/* Sizes */

/* Very specific fixes */
/* - inline switches */
section h5 + .field-wrapper.bootstrap-switch-wrapper { margin-bottom: 0; }

.field-wrapper.bootstrap-switch-wrapper .right-switch { position: absolute; right: 0; }
.field-wrapper.bootstrap-switch-wrapper .right-switch + label { padding-right: 60px; }
.col-* > .right-switch { right: @mainColPadding; }

.field-wrapper.switch-leveler { margin-top: 24px; }

/* inline edit inputs */
.boundGridInlineEdit { position: relative; }
.boundGridInlineEdit .btn-group { position: absolute; right: 6px; top: 6px; }

/* ==== editable-form ==== */
form.editable-wrap { text-align: left; }
.editable-wrap { display: inline-block; white-space: nowrap; margin: 0; } //class for single editable element
.editable-wrap .editable-controls, .editable-wrap .editable-error { display:inline-block; height:29px; position: relative; margin-bottom: 0; } // remove bottom-margin for bootstrap
.editable-wrap .editable-controls > input { color:#333; box-shadow: none; font-weight: normal; margin-bottom: 0; padding-right:150px; } // remove bottom-margin of inputs
.editable-wrap .editable-input { display: inline-block; } // keep buttons on the same line
.editable-buttons { position: absolute; right: 0px; height:29px; display: inline-block; }
.editable-buttons button { &:extend(button.small); text-transform: capitalize; height:100%; }
.editable-buttons button + button { border-left: 1px solid; }
.editable-buttons button.editable-save-btn { float:right;  }
.editable-buttons button.editable-cancel-btn { padding-right:15px; }
.editable-input.editable-has-buttons { width: auto; } // in bootstrap width: 100% => buttons go outside the box

/* ==== editable-bstime ==== */
.editable-bstime .editable-input input[type="text"] { width: 46px; } // workaround for bootstrap that sets width: 100% and inputs become too wide
.editable-bstime .well-small { margin-bottom: 0; padding: 10px; } // less padding for .well

/* ==== editable-range ==== */
.editable-range output { display: inline-block; min-width: 30px; vertical-align: top; text-align: center; }

/* ==== editable-color ==== */
.editable-color input[type="color"] { width: 50px; }

/* ==== editable-checkbox ==== */
/* ==== editable-checklist ==== */
/* ==== editable-radiolist ==== */
.editable-checkbox label span,
.editable-checklist label span,
.editable-radiolist label span { margin-left: 7px; margin-right: 10px; }

/* ==== element ==== */
.editable-hide { display: none !important; } // hiding element
.editable-click { cursor: pointer; text-decoration: none; }
.editable-click::after { 
    content: '\f303'; 
    display: none;
    font-family: "Font Awesome 5 Free"; 
    font-weight: 900; 
    margin-left: 1rem; 
    position:absolute;
    font-size: 75%; 
    margin-top: 3px;
}
.editable-click:hover { text-decoration: none; }
.editable-click:hover::after { display: inline-block; }
.editable-empty,
.editable-empty:hover,
.editable-empty:focus,
a.editable-empty,
a.editable-empty:hover,
a.editable-empty:focus { font-style: italic; text-decoration: none; } // editable-empty

.bootstrap-switch-wrapper { margin-top: 0; }

.field-wrapper.input-sm {
    min-height: auto;
    height: auto;
}


/* Underwriting Conditions */
.underwriting-condition-container { border: 1px solid #ededed; padding: 10px; background-color: white;}
.invalid .underwriting-condition-container {border-color:red;}
.underwriting-condition { position:relative; padding-left:20px; }
.underwriting-condition-root > .underwriting-condition {padding-left:0;}
.underwriting-condition-root > .underwriting-condition > .underwriting-condition-child-line-vertical, .underwriting-condition-root > .underwriting-condition > .underwriting-condition-child-line-horizontal {display:none;}
.underwriting-condition.first {margin-left:10px;}
.underwriting-condition-children { margin-left: 10px; }
.underwriting-condition-child-line-horizontal { width: 15px; border-top: 1px #aaa dotted; height: 1px;position: absolute; left: 0; top: 9px; }
.underwriting-condition-child-line-vertical { height: ~'calc(100% - 1px)'; border-left: 1px #aaa dotted; width: 1px;position: absolute; left: 0; top: 0; }
.last > .underwriting-condition-child-line-vertical {height: 9px;}

.underwriting-condition .field-wrapper { display:inline-block; margin:0; }
.underwriting-condition .field-wrapper select,
.underwriting-condition .field-wrapper input { padding:0px; }

.underwriting-condition-and .fa-plus:hover,
.underwriting-condition .fa-plus:hover  {color: @darkGray; }
.underwriting-condition .fa-pencil:hover { color: @darkGray; }
.underwriting-condition .fa-trash:hover { color: @red; }

.alert > .fa { margin-right:10px; }

.field-wrapper .validation-indicator { margin-top: 0px; vertical-align: bottom; display: inline-block; line-height: 8px;  }
[class^="col-"].field-wrapper .validation-errors { right:18px; }
.field-wrapper.empty-input .validation-indicator { color: red; margin-top: 0px; vertical-align: bottom; display: inline-block; line-height: 8px; }


body .themed.a3 {

    /* text inputs &*/
    input[type=text],       input[type=email],  input[type=url],    input[type=number],
    input[type=password],   textarea,           select,             .ui-select-match
    { 
        border-color: #d0d3d5; 
        background-color: #FFF;
    }

    input[type=text].white,       input[type=email].white,  input[type=url].white,    input[type=number].white,
    input[type=password].white,   textarea.white,           select.white,             .ui-select-match.white
    { background-color: @white; }

    /* input outline */
    input:not([type=button]):not([type=radio]):not([type=checkbox]):not([type=submit]):focus, textarea:focus, select:focus { border-color: @orange; }
    input:disabled, select:disabled, select:disabled, textarea:disabled { background-color: @white; border-color: @tabGray; }

    /* selects */
    select.unselected { color: @placeholderTextColor; }
    select.unselected option { color:@primaryFontColor; }
    select option[disabled] { color: @placeholderTextColor; }

    /* field-wrapper */
    .field-wrapper div.label { background: transparent; color: #333;}
    .field-wrapper .validation-indicator { color: red; }
    .field-wrapper input:not([type=checkbox]):not([type=radio]), .field-wrapper select { background-color:@white; }

    /* empty-input */
    .empty-input:not(.search) input:not([type=checkbox]):not([type=radio]),
    .empty-input select,
    .empty-input textarea { background: @white; }
    .empty-input:not(.search) input:not(:focus),
    .empty-input select:not(:focus),
    .empty-input textarea:not(:focus) { border-color: @tabGray;}

    // empty-input label
    .field-wrapper.empty-input.select div.label { }

    // disabled inputs
    .empty-input input:not([type=checkbox]):not([type=radio]):disabled, .empty-input select:disabled, .empty-input textarea:disabled { border-color: @gray; }
    .field-wrapper.empty-input.select.disabled select:disabled,
    .field-wrapper select:disabled { border-color: @gray; }
    .field-wrapper.empty-input.select.disabled div.label { color: #c3c3c3;  }
    .field-wrapper.empty-input .validation-indicator { color: red; }


    /* validation */
    // these are the classes for standard agnular validation and angular agility validation
    // .ng-invalid - angular adds when not valid field
    // .ng-dirty - angular adds when field has been changed
    // .aa-had-focus - angular agility adds on blur
    // .aa-invalid-attempt - angular agility adds when form submitted but was invalid
    // had focus && invalid                         field dirty && invalid                      form was submitted && invalid
    input[type=text].ng-invalid.aa-had-focus,       input[type=text].ng-invalid.ng-dirty,       input[type=text].ng-invalid.aa-invalid-attempt,
    input[type=email].ng-invalid.aa-had-focus,      input[type=email].ng-invalid.ng-dirty,      input[type=email].ng-invalid.aa-invalid-attempt,
    input[type=url].ng-invalid.aa-had-focus,        input[type=url].ng-invalid.ng-dirty,        input[type=url].ng-invalid.aa-invalid-attempt,
    input[type=number].ng-invalid.aa-had-focus,     input[type=number].ng-invalid.ng-dirty,     input[type=number].ng-invalid.aa-invalid-attempt,
    input[type=password].ng-invalid.aa-had-focus,   input[type=password].ng-invalid.ng-dirty,   input[type=password].ng-invalid.aa-invalid-attempt,
    textarea.ng-invalid.aa-had-focus,               textarea.ng-invalid.ng-dirty,               textarea.ng-invalid.aa-invalid-attempt,
    select.ng-invalid.aa-had-focus,                 select.ng-invalid.ng-dirty,                 select.ng-invalid.aa-invalid-attempt,
    .ui-select-match.ng-invalid.aa-had-focus,       .ui-select-match.ng-invalid.ng-dirty,       .ui-select-match.ng-invalid.aa-invalid-attempt
    { border-color: @warningOrange }


    .validation-error { color:@warningOrange; }

    /* validation alert notification */
    .validation-notification .alert-danger { 
        color: @gray; 
        background-color: @charcoal; 
        border-color: @warningOrange; 
        box-shadow: 2px 2px 2px @charcoal; 
    }
    
    .validation-notification ul li > a { 
        color: @orange;
    }

    /* search boxes */
    .search > div > i.fa-search { background-color: transparent; }

    /* search box colors */
    .search.white > div > input:not([type=button]):not([type=submit]) { 
        background-color:@white; 
        border-color:#ccc; 
    }
    
    .search.white > div > i {color:@orange;}
    .search .dropdown button { background: transparent; color: @placeholderTextColor; }

    /* Colors */
    input.white:not([type=button]):not([type=submit]) {background-color: @white; }
    input.black:not([type=button]):not([type=submit]) {background-color: @charcoal; color: @gray; }


    /* inline edit inputs */
    .boundGridInlineEdit input { background: @white; }

    /* ==== editable-form ==== */

    .editable-wrap .editable-controls > input { background: @white;  }

    /* ==== element ==== */

    .editable-click::after { color: #aaa; }
    .editable-click:hover { background: lightyellow; }
    .editable-empty,
    .editable-empty:hover,
    .editable-empty:focus,
    a.editable-empty,
    a.editable-empty:hover,
    a.editable-empty:focus { color: #DD1144; }

    .editable-buttons button { box-shadow:none; }

}
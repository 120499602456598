@import 'a3_variables.less';

.rider {
    table tr td:first-of-type { width:280px;}

    table tr .rider-change-selection {vertical-align:middle;}
    table tr .rider-change-originals {vertical-align: middle; text-align:left; }
    table tr .rider-change-values { width:200px; vertical-align:middle; }
    table tr .rider-change-selection .field-wrapper:first-of-type { margin-top:0; }
    table tr .rider-change-remove {text-align: right; width:65px; vertical-align:middle;}
}
@import 'a3_variables.less';

/* Style when highlighting a search. */
.ui-select-highlight { 
    font-weight: bold; 
}

.ui-select-offscreen { 
    clip: rect(0 0 0 0) !important; 
    width: 1px !important; 
    height: 1px !important; 
    border: 0 !important; 
    margin: 0 !important; 
    padding: 0 !important; 
    overflow: hidden !important; 
    position: absolute !important; 
    outline: 0 !important; 
    left: 0px !important; 
    top: 0px !important; 
}

/* Select2 theme */
/* Mark invalid Select2 */
.ng-dirty.ng-invalid > a.select2-choice { 
    border-color: @warningOrange; 
}

.select2-result-single { padding-left: 0; }

.select2-locked > .select2-search-choice-close{ display:none; }

/* Selectize theme */

/* Helper class to show styles when focus */
/*.selectize-input.selectize-focus{
    border-color: #007FBB !important;
}*/

/* Fix input width for Selectize theme */
.selectize-control > .selectize-input > input { width: 100%; }

/* Fix uib-dropdown width for Selectize theme */
.selectize-control > .selectize-dropdown { width: 100%; top: 36px !important; }

/* Mark invalid Selectize */
.ng-dirty.ng-invalid > div.selectize-input { border-color: @warningOrange; }

/* Fix Bootstrap uib-dropdown position when inside a input-group */
.input-group > .ui-select-bootstrap.dropdown { 
    /* Instead of relative */
    position: static;
}

.input-group > .ui-select-bootstrap > input.ui-select-search.form-control { 
    border-radius: 4px; /* FIXME hardcoded value :-/ */ 
    border-top-right-radius: 0; 
    border-bottom-right-radius: 0; 
}
.ui-select-bootstrap > .ui-select-match { 
    /* Instead of center because of .btn */ 
    text-align: left !important; }

.ui-select-bootstrap > .ui-select-match > .caret { 
    position: absolute; 
    top: 45%; 
    right: 15px; 
}

/* See Scrollable Menu with Bootstrap 3 http://stackoverflow.com/questions/19227496 */
.ui-select-choices { 
    width: 100%; 
    height: auto; 
    max-height: 200px; 
    overflow-x: hidden; 
}

.ui-select-multiple.ui-select-bootstrap { 
    height: auto; 
    padding: .3em; 
}

.ui-select-multiple.ui-select-bootstrap input.ui-select-search { 
    background-color: transparent !important; /* To prevent double background when disabled */ 
    border: none; 
    outline: none; 
    height: 1.666666em; 
    cursor: pointer; 
}

.ui-select-multiple.ui-select-bootstrap .ui-select-match .close { 
    font-size: 1.6em;
    line-height: 0.9; 
}

.ui-select-multiple.ui-select-bootstrap .ui-select-match-item { 
    outline: 0; 
    background-color:@lightOrange; 
}

.ui-select-no-choice li {
  padding: .5rem 1rem;
}

.ui-select-choices-row > a { 
    display: block; 
    padding: 3px 10px; 
    clear: both;
    font-weight: 400; 
    line-height: 1.42857143; 
    color: #333; 
    white-space: nowrap; 
}

.ui-select-choices-row > a:hover, 
.ui-select-choices-row > a:focus { 
    text-decoration: none; 
    color: #262626; 
    background-color: #f5f5f5; 
}

.ui-select-choices-row.active > a { 
    text-decoration: none;
    outline: 0;
    background-color:@lightOrange;
    color:inherit; 
}

.ui-select-choices-row.disabled > a, 
.ui-select-choices-row.active.disabled > a { 
    color: @gray; 
    cursor: not-allowed; 
    background-color: @white; 
}

/* fix hide/show angular animation */
.ui-select-match.ng-hide-add, 
.ui-select-search.ng-hide-add { display: none !important; }

.ui-select-match:focus { outline: none; }

/* Mark invalid Bootstrap */
.ui-select-bootstrap.ng-dirty.ng-invalid > button.btn.ui-select-match { 
    border-color: @warningOrange; 
}

.select2 > .select2-choice.ui-select-match { 
    /* Because of the inclusion of Bootstrap */ 
    height: 29px !important; 
}

.ui-select-multiple, 
.ui-select-bootstrap { width: 100%; }

.ui-select-multiple .ui-select-match { 
    background: transparent !important; 
    box-shadow: none; 
    border: none; 
    padding:0; 
}

.ui-select-multiple .ui-select-match > span {
    margin-right: 10px;
    display: inline-block;
    background-color: #ffa400;
    border-radius: 2px;
    padding: 3px 7px;
    color: #fff; 
}

.ui-select-multiple .ui-select-match > span .close { 
    float: right; 
    font-size: 14px; 
    font-weight: normal; 
    line-height: 30px; 
    margin-left:10px; 
    color: @white; 
    text-shadow: none; 
    opacity:1; 
    line-height:16px;
}

.ui-select-multiple .ui-select-multiple-input-wrapper { 
    margin-top:15px; 
}

.ui-select-multiple .ui-select-match-item:focus{ 
    outline:none; 
}
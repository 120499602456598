@import 'a3_variables.less';

/* header */
.nav-tabs { border: 0; margin-top:15px; }
.nav-tabs li a { border-radius: 0; }
.nav-tabs li.active a { font-weight:normal; }
.nav-tabs li.active a:focus { outline:none; }


.nav-tabs > li > a { border: 0; }
.nav-tabs li a { }

.tab-header { overflow:auto; zoom:1; }
.tab-header .tab-section { padding:10px 8px; float:left; }
.tab-header .tab-section.on { font-weight:bold;  }

/* main tab container */
.tab-pane { width:100%; padding:12px; text-align:left; box-sizing:border-box;position:relative; min-height:150px;}
.tab-pane table { text-align:left; }

/* buttons */
.tabs ~ button, .tabs ~ input[type=submit] { margin:20px 0 20px 5px; }
// buttons at the bottom of tab-pane
.tab-pane button:last-child, .tab-pane input[type=button]:last-child, .tab-pane input[type=submit]:last-child { margin-bottom: 0; }

/* area above tabs */
.tabs.above { }
.tabs.above .body { padding:0 0 0 0; text-align:left; }

/* tab heading buttons */
.nav-tabs li a button i { margin: 0; }
.nav-tabs li a button { padding: 2px 5px; margin-left: 5px; }

/* sections in tabs */
.tab-pane section.above { padding-top: 0; }

/* loading mask in tab-pane */
.tabs .tab-pane .busy-indicator-default-sign { top: 47px; }
.tabs .tab-pane .busy-indicator-backdrop { display: none; }

// Justified tabs
ul.nav.nav-tabs.nav-justified { border-bottom: none; }
.nav-tabs.nav-justified > li > a { border-radius: 0;}
ul.nav.nav-tabs.nav-justified > li:last-of-type > a {border-right:none;}

// Wizard 
div.wizard.disabled-tabs > ul.nav-tabs::before {content: ''; width:100%; position:absolute; top:0; left:0; height:70px; z-index:1002}
div.wizard.disabled-tabs > ul.nav-tabs { position:relative; }
div.wizard > ul li a::before { content: counter(wizard-step); position: absolute; width: 26px; height: 26px; background-color: #ebebeb; border-radius: 30px; top: -15px; left: ~"calc(50% - 12px)"; z-index: 2; counter-increment: wizard-step; font-size: 11px; border: 2px solid #AAA; color: white; padding: 3px;font-weight: bold; }
div.wizard > ul li a::after { content: ''; position: absolute; background-color: #ebebeb; height: 2px; left: 0; top: -2px; width: 100%; left: -50%; z-index:1; }
div.wizard > ul li:first-of-type a::after { width:0px; }
div.wizard > ul.nav-tabs { border:none; background-color:transparent; counter-reset:wizard-step; }
div.wizard > ul.nav-tabs > li.active > a {border:none; background-color:transparent; }
div.wizard > ul.nav-tabs > li.active > a:hover {border:none; background-color:transparent; }
div.wizard > ul.nav-tabs > li.active > a:focus {border:none; background-color:transparent; }
div.wizard > ul.nav-tabs > li:first-of-type > a {border-left:none;}
div.wizard > ul.nav-tabs > li > a {border:none;}
div.wizard > ul.nav-tabs > li > a:hover {border:none; background-color:transparent; }

div.wizard.transparent > .tab-content > .tab-pane { background: transparent; padding: 0; }

ul.vertical-tab-nav {
    padding: 10px 10px 0 10px;
    list-style: none;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 0;
    border-radius: 2px;
}

ul.vertical-tab-nav li {
    margin-bottom: 5px;
}

ul.vertical-tab-nav li a {
    padding: 5px 10px;
    display: inline-block;
    width: 100%;
    border-right: 5px solid transparent;
    text-decoration: none;
}

body .themed.a3 {
    /* header */
    .nav-tabs li a { color: @primaryFontColor; }
    .nav-tabs li.active a { background-color: @tabGray; }
    .nav-tabs li.active a:focus { background-color: @tabGray;  }
    .nav-tabs li.active a:hover { background-color: @tabGray; }

    .tab-header .tab-section.on {  background:@tabGray; }

    /* main tab container */
    .tab-pane { background:@tabGray; }

    .tabs.above { background-color:transparent; }

    div.wizard ul li a::before { background-color: @orange; }
    div.wizard ul li a::after { background-color: @orange; }
    
    div.wizard ul li.active ~ li a::after {background-color:#ccc;}
    div.wizard ul li.active ~ li a::before {background-color:#ccc;}

    ul.vertical-tab-nav {
        background: white;
    }

    ul.vertical-tab-nav li a {
        color: #333;
    }

    ul.vertical-tab-nav li a:hover, 
    ul.vertical-tab-nav li.active a {
        border-right-color: #ffa400;
        color: #ffa400;
        background-color: #333c48;
    }
}

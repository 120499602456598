@import 'a3_variables.less';

#quoteSelection .quote { overflow: hidden; position: relative; }
#quoteSelection .quote-inner header { display: none; }
#quoteSelection .quote-inner { background: @white; border-bottom: 1px solid @gray; display: table; overflow: hidden; padding: 15px 0; position: relative; width: 100%; }
#quoteSelection .first .quote-inner { border-radius: @baseBorderRadius @baseBorderRadius 0 0; }
#quoteSelection .last .quote-inner { border-radius: 0 0 @baseBorderRadius @baseBorderRadius; border: 0; }
#quoteSelection .quote-inner .underrider,
#quoteSelection .quote-inner .term,
#quoteSelection .quote-inner .quick-glance-icons,
#quoteSelection .quote-inner .premium,
#quoteSelection .quote-inner .actions { display: table-cell !important; float: none !important; margin: 0 !important; vertical-align: middle !important; width: 20% !important; }
#quoteSelection .quote-inner .term,
#quoteSelection .quote-inner .quick-glance-icons { width: 12% !important; }
#quoteSelection .quote-inner .premium { width: 18% !important; }
#quoteSelection .quote-inner .underrider img { max-height: 60px; padding-bottom: 5px; }
#quoteSelection .quote-inner .underrider span,
#quoteSelection .quote-inner .premium span { display: block; font-size: 12px; }
#quoteSelection .quote-inner .term,
#quoteSelection .quote-inner .premium { font-size: 18px; }
#quoteSelection .quote-inner .quick-glance-icons { font-size: 21px; }
#quoteSelection .quote-inner .quick-glance-icons i { position: relative; }
#quoteSelection .quote-inner .quick-glance-icons i:not(.on) { color: @tabGray; }
#quoteSelection .quote-inner button { position: relative; }
#quoteSelection .quote-inner i .tooltip,
#quoteSelection .quote-inner button .tooltip { display: none; }
#quoteSelection .quote-inner i:hover .tooltip,
#quoteSelection .quote-inner button:hover .tooltip { bottom: 102%; display: block; font-family: @mainFont; font-weight: 400; left: 50%; margin-left: -100px; opacity: 0.9; }
#quoteSelection .quote-inner i:hover .tooltip-arrow,
#quoteSelection .quote-inner button:hover .tooltip-arrow { display: block; }
#quoteSelection .quote-inner i:hover .tooltip-inner,
#quoteSelection .quote-inner button:hover .tooltip-inner { display: block; width: 200px; }
#quoteSelection .quote-inner .actions button { margin: 0; padding: 12px 0; width: 100%; }
#quoteSelection .quote-inner .actions .sub-actions-group { margin-top: 10px; }
#quoteSelection .quote-inner .actions button.email-btn { margin-right: 4%; width: 24%; }
#quoteSelection .quote-inner .actions button.download-btn { width: 72%; }
#quoteSelection .quote-inner .pending-quote { display: table-cell !important; float: none !important; margin: 0 !important; vertical-align: middle !important; width: 20% !important;}
#quoteSelection .quote-inner .pending-quote strong { font-size:20px; }
#quoteSelection .quote-details { background: @white; border-bottom: 1px solid @gray; display: none; overflow: hidden; padding-bottom: 20px; }
#quoteSelection .quote-details h5 { font-size: 12px; font-weight: 700; margin: 20px 0 6px 0; text-transform: uppercase; }
#quoteSelection .quote-details p { margin-bottom: 0; }
#quoteSelection .quote-details p.quote-status { margin-top: 1em;}
#quoteSelection .quote-details p.quote-status.approved { color: @green; }
#quoteSelection .quote-details i { width: 20px; }
#quoteSelection .quote-details i:not(.on) { color: @tabGray; }
#quoteSelection .quote.on:not(.sponsoredQuote) .quote-inner { border-bottom: 1px solid @orange; border-radius: 0; }
#quoteSelection .quote.on .quote-details { display: block; }
#quoteSelection .quote.last.on .quote-details { border-radius: 0 0 @baseBorderRadius @baseBorderRadius; }
#quoteSelection .sponsoredQuote { margin-bottom: 15px; }
#quoteSelection .sponsoredQuote .quote-inner { border: 1px solid @orange; border-radius: @baseBorderRadius; padding-top: 37px; }
#quoteSelection .sponsoredQuote .quote-inner header { background: @orange; color: @white; display: block; font-size: 11px; line-height: 2em; padding: 0 15px; position: absolute; top: 0; width: 100%; }
#quoteSelection .sponsoredQuote.on .quote-inner { box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2); }
#quoteSelection .sponsoredQuote .quote-details { border-radius: 0 0 @baseBorderRadius @baseBorderRadius; display: none; margin-top: -5px; padding-top: 5px; }
#quoteSelection .sponsoredQuote.on .quote-details { border: none; }
#quoteSelection button.details-toggle { bottom: 0; border-radius: @baseBorderRadius @baseBorderRadius 0 0; font-size: 11px; font-weight: 400; left: 50%; margin-left: -50px; padding: 4px; position: absolute; text-transform: uppercase; width: 100px; }
#quoteSelection .pending .premium { opacity: 0.5; }

#filters { background: @white; bottom: 0; display: none; padding: 20px; position: absolute; right: 0; top: 42px; width: 350px; }
#filters header { margin-bottom: 20px; overflow: hidden; }
#filters h5 { margin-top: 20px; }
#filters h5 i { font-size: 18px; }
#filters .checkbox { margin: 0; }
#filters .checkbox label { font-weight: 400; }
#filters .checkbox i:not(.on) { color: @tabGray; }

#quoteSelection .busy-indicator-backdrop { display: none; }

.right-pane #filters { display: block; }
.right-pane #quoteSelection .busy-indicator-backdrop { display: block; height: calc(~'100% - 42px'); margin-right: 350px; top: 42px; }

/* responsive */
@media (max-width: 1366px) {
    #filters { top: 40px; width: 300px; }
    .right-pane #quoteSelection .busy-indicator-backdrop { height: calc(~'100% - 40px'); margin-right: 300px; top: 40px; }
}

// "medium"
@media (max-width: 1199px) {
    #quoteSelection .quote-inner .actions button.email-btn { margin-right: 8%; width: 46%; }
    #quoteSelection .quote-inner .actions button.download-btn { width: 46%; }
}


body .themed.silver {
    #quoteSelection .quote.on:not(.sponsoredQuote) .quote-inner { border-bottom: 1px solid #627789; }
    #quoteSelection .sponsoredQuote .quote-inner { border: 1px solid #627789;}
    #quoteSelection .sponsoredQuote .quote-inner header { background: #627789; color: white;}
    #quoteSelection button.details-toggle { border:none; }
}
@import 'a3_variables.less';


/* side menu */
.side-menu { width:auto; height:100%; position:fixed; top:0; bottom:0; left:0; }

.side-menu-collapsed-show { display:none; }

.side-menu .menu-content { width: 250px; }

/* side menu logo */
.main-logo { display: block; font-size:18px; height: 34px; line-height:20px; margin: 14px auto; width: 172px; }
.main-logo img { height:auto; max-width:100%; vertical-align:middle; border:0; }


/* side menu content */
.side-menu ul { padding-left:0; list-style:none; }
.side-menu > ul { margin:0; }
.side-menu > ul > li.current a { text-decoration:none; padding-right:15px; }
.side-menu ul li.current ul li a { border:none; }
.side-menu ul li a { display:block; padding:11px 20px 10px 15px; }
.side-menu ul li a i {margin-right:11px;}
.side-menu ul li a:hover { text-decoration:none;  }
.side-menu ul li .sub-menu { display:none; }
.side-menu ul li.current .sub-menu { display:block; }

.side-menu ul li ul li a { padding: 11px 20px 10px 15px; }
.side-menu ul li a .arrow { float: right; margin-top: 3px; }

// collapsed
.side-menu-collapsed .side-menu .menu-content { width: 50px; }
.side-menu-collapsed .main-logo { width: 50px; }
.side-menu-collapsed .main-logo { width: 30px; margin:18px 10px 10px 10px; }
.side-menu-collapsed .side-menu ul li a { font-size:18px; padding:7px 0 8px 0; text-align: center; }
.side-menu-collapsed .side-menu ul li a i { margin: 0; }
.side-menu-collapsed .side-menu-collapsed-hidden { display:none; }
.side-menu-collapsed .side-menu-collapsed-show { display: initial; }

/* search bar */
.side-menu .search { margin:20px; position:relative; }
.side-menu .search:before { position:absolute; right:15px; content:'\f002'; font-family:'fontAwesome'; line-height:38px; }
.side-menu .search input { width:210px; padding:4px 15px; font-size:10px; border:none; border-radius:5px; }


.sub-menu-slide { }
.sub-menu-slide .sub-menu { list-style: none; padding-left:0; }
.sub-menu-slide header { border-bottom-width: 1px; border-bottom-style: solid; padding: 5px 15px; }
.sub-menu-slide header i { margin-right:15px; }
.sub-menu-slide .sub-menu li { border-bottom-width: 1px; border-bottom-style: solid; }
.sub-menu-slide .sub-menu li a { padding: 9px 20px 10px 15px; display:block; text-decoration:none; }
.sub-menu-slide .sub-menu li a:hover, .sub-menu-slide .sub-menu li a:focus { text-decoration:none; }

/* responsive */
@media (max-width: 1366px) {
}

@media (max-width: 850px) {
    
}


body .themed.a3 {
    .side-menu {background-color: @charcoal;}

    .side-menu ul > li:last-of-type { border-bottom:1px solid #464e58; }
    
    .side-menu ul > li.current > a { text-decoration:none; border-right:5px solid @orange; }
    
    .side-menu ul li { border-top:1px solid #464e58; }
    .side-menu ul li a { color:#edf0f2; }
    .side-menu ul li a:hover { text-decoration:none; background:#2a333b; }

    .side-menu ul li ul li { border-top:1px solid #333; background-color: #464e58; }
    .side-menu ul li ul li a { padding-left:30px; }

    .side-menu ul li.current ul li.current a { border-right:5px solid #ffd08d; }
    .side-menu ul li ul.sub-menu { border-top: 1px solid black; }

    .side-menu .search:before { color:@orange; }
    .side-menu .search input { background-color:#2a333b; color:rgba(255,255,255,.75); }

    .sub-menu-slide { background-color: #464e58; color: #edf0f2; }
    .sub-menu-slide header { border-bottom-color: #333; background-color: #d0d3d5; color: @charcoal; }
    .sub-menu-slide .sub-menu li { border-bottom-color: #333; }
    .sub-menu-slide .sub-menu li a { color: #edf0f2; }
    .sub-menu-slide .sub-menu li a:hover, .sub-menu-slide .sub-menu li a:focus {  background-color:#2a333b; }
    
}

body .themed.a3.side-menu-collapsed .side-menu ul > li.current > a { padding-left:5px; }
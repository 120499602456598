@import 'a3_variables.less';
@import 'dark_variables.less';

body .themed.dark {
    /* text inputs &*/
    input[type=text],       input[type=email],  input[type=url],    input[type=number], 
    input[type=password],   textarea,           select,             .ui-select-match 
    { 
        border-color: @dark_inputBorderColor; 
        background-color: @dark_inputBackground; 
        color: @dark_inputText; 
    }

    input::placeholder,
    select::placeholder {
        color: @dark_placeholderTextColor;
    }

    input:focus::placeholder,
    select:focus::placeholder {
        color: @dark_placeholderTextColor_focus;
    }

    input[type=text].white,       input[type=email].white,  input[type=url].white,    input[type=number].white, 
    input[type=password].white,   textarea.white,           select.white,             .ui-select-match.white 
    { 
        background-color: @dark_inputBackground; 
    }

    /* input outline */
    input:not([type=button]):not([type=radio]):not([type=checkbox]):not([type=submit]):focus, 
    textarea:focus, 
    select:focus { 
        border-color: @dark_inputBorderColor; 
    }
    
    input:disabled, 
    select:disabled, 
    select:disabled, 
    textarea:disabled { 
        background-color: @dark_inputBackground; 
    }

    /* selects */
    select.unselected { 
        color: @dark_inputText; 
    }
    
    select.unselected option { 
        color: @dark_primaryFontColor; 
    }
    
    select option[disabled] { 
        color: @dark_inputText; 
    }

    select option {
        color: @dark_primaryFontColor;
        background-color: @dark_selectBackgroundColor;
    }
    
    select optgroup {
        color: @dark_primaryFontColor;
        background-color: @dark_selectBackgroundColor;
    }

    /* field-wrapper */
    .field-wrapper div.label { 
        color:@dark_inputText; 
    }
    
    .field-wrapper .validation-indicator {
        color: @dark_red; 
        margin-top: 0px; 
        vertical-align: bottom; 
        display: inline-block; 
        line-height: 8px;  
    }
    
    .field-wrapper input:not([type=checkbox]):not([type=radio]), 
    .field-wrapper select { 
        background-color: @dark_inputBackground; 
    }

    /* empty-input */
    .empty-input:not(.search) input:not([type=checkbox]):not([type=radio]),
    .empty-input select,
    .empty-input textarea { 
        background: @dark_inputBackground; 
    }
    
    .empty-input:not(.search) input:not(:focus),
    .empty-input select:not(:focus),
    .empty-input textarea:not(:focus) { 
        border-color: @dark_inputBorderColor;
    }    

    // empty-input label
    .field-wrapper.empty-input.select div.label { }

    // disabled inputs
    .empty-input input:not([type=checkbox]):not([type=radio]):disabled, 
    .empty-input select:disabled, 
    .empty-input textarea:disabled { 
        background: @dark_inputBackground; 
        border-color: @dark_inputBorderColor; 
    }
    
    .field-wrapper.empty-input.select.disabled select:disabled,
    .field-wrapper select:disabled { 
        border-color: @dark_inputBorderColor; 
    }
    
    .field-wrapper.empty-input .validation-indicator { 
        color: @dark_red; 
        margin-top: 0px; 
        vertical-align: bottom; 
        display: inline-block; 
        line-height: 8px; 
    }

    .inline-label { 
        font-weight: normal; 
    }

    /* validation */
    // these are the classes for standard agnular validation and angular agility validation
    // .ng-invalid - angular adds when not valid field
    // .ng-dirty - angular adds when field has been changed
    // .aa-had-focus - angular agility adds on blur
    // .aa-invalid-attempt - angular agility adds when form submitted but was invalid
    // had focus && invalid                         field dirty && invalid                      form was submitted && invalid
    input[type=text].ng-invalid.aa-had-focus,       input[type=text].ng-invalid.ng-dirty,       input[type=text].ng-invalid.aa-invalid-attempt, 
    input[type=email].ng-invalid.aa-had-focus,      input[type=email].ng-invalid.ng-dirty,      input[type=email].ng-invalid.aa-invalid-attempt,   
    input[type=url].ng-invalid.aa-had-focus,        input[type=url].ng-invalid.ng-dirty,        input[type=url].ng-invalid.aa-invalid-attempt,  
    input[type=number].ng-invalid.aa-had-focus,     input[type=number].ng-invalid.ng-dirty,     input[type=number].ng-invalid.aa-invalid-attempt,  
    input[type=password].ng-invalid.aa-had-focus,   input[type=password].ng-invalid.ng-dirty,   input[type=password].ng-invalid.aa-invalid-attempt,
    textarea.ng-invalid.aa-had-focus,               textarea.ng-invalid.ng-dirty,               textarea.ng-invalid.aa-invalid-attempt,
    select.ng-invalid.aa-had-focus,                 select.ng-invalid.ng-dirty,                 select.ng-invalid.aa-invalid-attempt,
    .ui-select-match.ng-invalid.aa-had-focus,       .ui-select-match.ng-invalid.ng-dirty,       .ui-select-match.ng-invalid.aa-invalid-attempt
    { 
        border-color: @dark_warningOrange 
    }

    .validation-error { 
        color: @dark_warningOrange; 
    }

    /* validation alert notification */
    .validation-notification .alert-danger { 
        color: @dark_gray; 
        background-color: @dark_charcoal; 
        border-color: @dark_warningOrange; 
        box-shadow: 2px 2px 2px @dark_charcoal; 
    }
    
    .validation-notification ul li > a { 
        color: @dark_orange;  
    }

    /* search boxes */
    .search > div > i.fa-search { 
        background-color: @dark_inputBackground; 
        height: 25px;
    }

    /* search box colors */
    .search.white > div > input:not([type=button]):not([type=submit]) { 
        background-color: @dark_inputBackground; 
        border-color: @dark_inputBorderColor; 
        box-shadow:none; 
    }
    
    .search.white > div > input:not([type=button]):not([type=submit]):focus { 
        border-color: @dark_inputBorderColor; 
    }
    
    .search.white > div > i { 
        color: @dark_inputText; 
    }
    
    .search .dropdown button {
        background: transparent; 
        color: @dark_inputText; 
    }
    
    .search .dropdown button:hover { 
        background: @dark_primaryBackgroundColor; 
        color: @dark_inputText; 
    }


    /* Colors */
    input.white:not([type=button]):not([type=submit]) {
        background-color: @dark_white; 
    }
    
    input.black:not([type=button]):not([type=submit]) {
        background-color: @dark_charcoal; 
        color: @dark_gray; 
    }

    /* inline edit inputs */
    .boundGridInlineEdit input { 
        background: @dark_inputBackground; 
    }

    /* ==== editable-form ==== */
    .editable-wrap .editable-controls > input { 
        background: @dark_inputBackground; 
    } 

    /* ==== element ==== */
    .editable-click::after { 
        color: @dark_linkColor; 
    }
    
    .editable-click:hover { 
        background: transparent;
        color: @dark_linkColor; 
    }
    
    .editable-empty, 
    .editable-empty:hover, 
    .editable-empty:focus,
    a.editable-empty, 
    a.editable-empty:hover, 
    a.editable-empty:focus { 
        color: @dark_red; 
    } 

    .bootstrap-switch-wrapper label {
        font-weight:normal;
    }

    .ats-switch span.switch-right {
        background-color: @dark_switchRightBackgroundColor;
        color: @dark_switchRightFontColor;
    }

    .ats-switch .knob {
        background-color: @dark_switchKnobBackgroundColor;
    }

    .ats-switch .knob i {
        text-shadow: none;;
    }
}
@import 'a3_variables.less';

body .themed {

    /* top menu */
    .top-menu { border-bottom-width: 1px; border-bottom-style: solid; height:63px; padding:16px 20px 15px 20px; position:fixed; top:0; left:250px; right:0; flex:1; z-index:100; }
    .top-menu:after { content:""; display:table; clear:both; }
    .top-menu.shadowed { box-shadow: 0 2px 12px -4px #999; }

    &.side-menu-collapsed .top-menu { left: 50px; }

    // toggle collapse button
    .toggle-collapse { left: -1px; position: absolute; top: 0; padding: 5px; }
    .toggle-collapse i {margin:0;}

    /* buttons */
    .top-menu .navbar-left button { float:left; box-shadow: none; font-weight:normal; }

    /* avatar */
    .top-menu img { width:32px; height:32px; float:right; border-radius:20px; }

    /* left navbar */
    .navbar-left { padding:0; margin:0 0 0 15px; list-style:none; }
    .navbar-left > li { float:left; position:relative; }
    .navbar-left > li:not(:first-of-type) { padding-left:10px; }

    /* search bar */

    .top-menu .global-search { float: right; margin: 0 10px; position: relative; text-align: left; width: 33.333333%; }
    .top-menu .global-search::before { content: '\f002'; display: block; font-family: 'Font Awesome 5 Free'; left: 8px; pointer-events: none; position: absolute; top: 5px; z-index: 1001; }
    body .top-menu form.global-search .global-search-input input[type=text] {
        height: 25px;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        border-right: none; 
        z-index: 1000; 
    }
    body .top-menu form.global-search .global-search-input input[type=text]:focus,
    body .top-menu form.global-search .global-search-input input[type=text]:active {
        border-right: none;
    }

    .top-menu .global-search .search .dropdown {display: none;}
    .top-menu .global-search .search-results { background: white; border-radius: 2px; min-width: 450px; overflow: hidden; position: absolute; right: 0; top: 38px; width: 100%; z-index: 1000; -webkit-transition:all linear 0.1s; transition:all linear 0.1s; }
    .top-menu .global-search .search-results.ng-hide { opacity: 0; }
    .top-menu .global-search .search-results .results-category { border-top-width: 1px; border-top-style: solid; list-style: none; margin: 0; padding: 10px 0 0; }
    .top-menu .global-search .search-results .results-category:first-child { border-top: 0; }
    .top-menu .global-search .search-results .results-category i { display: inline-block; }
    .top-menu .global-search .search-results .results-category li.title { font-size: 80%; padding: 0 10px 4px; text-transform: uppercase; }
    .top-menu .global-search .search-results .results-category li:not(.title) { cursor: pointer; padding: 5px 10px 5px 20px; text-decoration: none; }
    .top-menu .global-search .search-results .results-category li:not(.title):hover { }
    .top-menu .global-search .search-results .results-category small { display: block; }
    .top-menu .global-search .search-results .results-category .search-result-info {display: inline-block; }
    .top-menu .global-search .search-results .results-category button {margin-top: 6px;}
    .top-menu .global-search .search-results .results-category.searching,
    .top-menu .global-search .search-results .results-category.no-results { border: none; padding: 5px 0; }
    .top-menu .global-search .field-wrapper { margin-top: 0; }
    .top-menu .global-search button.search-button {
        height: 25px; 
        width: 35px; 
        padding: 0; 
        text-align: center; 
        border-top-left-radius: 0; 
        border-bottom-left-radius: 0; 
        box-shadow: none;
        border: 1px solid; 
    }

    .search-overlay { position:fixed; left:0; right:0; top:0; bottom:0; z-index:100; -webkit-transition:all linear 0.1s; transition:all linear 0.1s; }
    .search-overlay.ng-hide { opacity: 0; }

    /* right navbar */
    .navbar-right { margin:0; margin-top:-3px; padding-left:0; float:right; list-style:none; }
    .navbar-right li { float:left; position:relative; margin-left:10px; }
    .navbar-right li > a .user-avatar .avatar-img { min-width:30px; padding:8px; display:block; text-align:center; text-decoration:none; border-radius:30px; }
    .navbar-right li > a:hover, 
    .navbar-right > li > a:active, 
    .navbar-right > li > a:focus, 
    .navbar-right > li > a:visited {text-decoration:none;}


    // Profile Dropdown
    .profile-dropdown { width:500px; }
    .profile-dropdown .col-sm-8 { padding-right: 20px; }
    .profile-dropdown .user-avatar .avatar-img { width: 100px; height: 100px; border-radius: 80px; float: none; margin-top:10px; margin-left:35px }
    .profile-dropdown .user-profile-button { margin-left: 35px; }
    .profile-dropdown h4 {margin-bottom: 0;}
    .profile-dropdown .change-password-button {padding-left: 0;}
    .profile-dropdown .logout-button { width: 100%; margin-top:15px; margin-bottom: 5px; }
    .profile-dropdown .field-wrapper { margin-top: 15px; }
    .profile-dropdown .button-container { height: 135px; }

    // Notification Dropdown
    .navbar-right .notifications { height: 32px; width:32px; position: relative; }
    .navbar-right .notifications > a { width: 32px; height: 32px; display: inline-block; }
    .navbar-right .notifications > a > i { background: transparent; padding: 0; font-size: 24px; left: 0; top: 5px; position: absolute;}
    .navbar-right .notifications > a > .badge { position: absolute; top: 0; left: 20px; padding: 2px; z-index: 100; }
    .notifications .dropdown-menu { width: 400px; padding:0; background-color:#ededed;}
    .notifications .dropdown-menu > header { padding: 10px; border-bottom:solid 1px #ccc; background-color:white;}
    .notifications .dropdown-menu > main { height:475px; }
    .notifications .dropdown-menu .empty-state { padding:20px; }
    .notifications .initial-state { border: none; margin-top: 90px; background-color: transparent; }
    .notifications .initial-state .empty-backdrop { display: none; }
    .notifications .notification { margin-top:5px; background-color:white; padding: 5px 10px 5px 0; }
    .notifications .notification:last-of-type { margin-bottom:5px; }
    .notifications .notification:hover { background: #ededed; }
    .notifications .notification .notification-icon { float:left; width:70px; height:70px; margin-top:3px; }
    .notifications .notification .notification-actions { color: #ccc; opacity:0 }
    .notifications .notification:hover .notification-actions { opacity: 1; }
    .notifications .notification .notification-actions button { padding: 0; background-color: transparent; border: none; color: #ccc; box-shadow:none; }
    .notifications .notification .notification-actions button:hover { color:@charcoal; }
    .notifications .notification .notification-content { padding: .5rem 1rem; cursor: pointer; }
    .notifications .notification .notification-content header { font-weight:bold; color:#627789; }
    .notifications .notification .notification-content main { font-size: 85%; padding: 3px 0; }
    .notifications .notification .notification-content footer { font-size: 75%; color:#999; }

    // notification loading mask
    .notification-loading-mask-wrapper { position: absolute; height:~'calc(100% - 40px)'; width:100%; bottom:0; }
    .notification-loading-mask-wrapper > div { height:100%; width:100%; }
    .notification-loading-mask-wrapper .busy-indicator-default-wrapper { position: absolute; left:0; top:0; right:0; bottom:0;}
    .notification-loading-mask-wrapper .busy-indicator-default-wrapper .busy-indicator-default-sign { position: absolute; width: 100%; left: 0; right: 0; bottom:0; top:auto; height:35px; padding-top:4px; }
    .notification-loading-mask-wrapper .busy-indicator-default-wrapper .busy-indicator-default-spinner {display: inline; top:auto; left: auto; }
    .notification-loading-mask-wrapper .busy-indicator-default-wrapper .busy-indicator-default-text {display:inline; margin-left:35px; }
    .notification-loading-mask-wrapper .busy-indicator-default-wrapper .busy-indicator-default-spinner div {width: 4%; height: 20%; background: #555; }

    /* responsive */
    @media (max-width: 1366px) {
    }

    @media (max-width: 850px) {  
    }

}

body .themed.a3 {
    .top-menu { border-bottom-color: rgba(0,0,0,.15); background:@white; }
    .top-menu .navbar-left button { background:@gray; color:@charcoal; border-color: #dedede; }
    .top-menu .global-search::before { color: @darkGray; }
    body .top-menu form.global-search .global-search-input input[type=text] {
        background-color: #f9f9f9; 
    }
    .top-menu .global-search .search-results .results-category { border-top-color: @tabGray; }
    .top-menu .global-search .search-results .results-category li.title { color: @lightFontColor; }
    .top-menu .global-search .search-results .results-category li:not(.title) { color: @primaryFontColor;  }
    .top-menu .global-search .search-results .results-category li:not(.title):hover { background: @gray; border-color: @orange; }
    .top-menu .global-search button.search-button { border-color: #d0d3d5 }
    
    .search-overlay { background:rgba(51,60,72,0.7); }
    .navbar-right > li > a > i { background:@orange; color:#fff; }

    .top-menu .toggle-collapse { background-color: #333c48; color:#edf0f2; border-right: none; border-bottom: none;  }

    .top-menu .profile-dropdown button.transparent { color: #333c48; }

    .navbar-right .notifications > a > i { background: transparent; color: @orange; }
    .navbar-right .notifications > a > i { color: @orange; }
}
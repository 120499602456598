@import 'a3_variables.less';

/* transition classes */
.fx { transition:all .2s ease-out; }

/* cube spinner animation */
.spinner { width:42px; height:54px; margin:10px auto 0; display:none; position:relative; }
.cube1, .cube2 { width:10px; height:10px; position:absolute; top:0; left:0; background-color:@orange; animation:cubemove 1.8s infinite ease-in-out; }
.cube2 { animation-delay: -0.9s; }

.ng-hide.ng-hide-animate { display: none !important; }

@keyframes cubemove {
  25% { transform: translateX(42px) rotate(-90deg) scale(0.5);} 
  50% { transform: translateX(42px) translateY(42px) rotate(-179deg); } 
  50.1% { transform: translateX(42px) translateY(42px) rotate(-180deg);} 
  75% { transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);} 
  100% {transform: rotate(-360deg); }
}

/* Fade in view animation */
.fadeIn .ng-enter,
.fadeIn .ng-leave { position: absolute; left: 0; right: 0; transition:all .5s ease-in-out; }
.fadeIn .ng-enter { transform: translate3d(100%, 0, 0); }
.fadeIn .ng-enter-active { transform: translate3d(0, 0, 0); }
.fadeIn .ng-leave { transform: translate3d(0, 0, 0); }
.fadeIn .ng-leave-active { transform: translate3d(-100%, 0, 0); }
@import 'a3_variables.less';

body .themed.silver {
    .side-menu {background-color: #f5f5f5; border-right:1px solid #ebebeb;}

    .side-menu  ul > li:last-of-type { border-bottom:1px solid #f5f5f5; }
    .side-menu  ul > li.current { background:#f5f5f5; }

    .side-menu  ul > li.current > a { text-decoration:none; border-right:6px solid @orange; }
    .side-menu-collapsed .side-menu ul > li.current > a { padding-left:5px; }

    .side-menu ul li { border-top:1px solid #f5f5f5; }
    .side-menu ul li a { color:#1b4461; padding-top:10px;}
    .side-menu ul li a:hover { text-decoration:none; color:@orange; }

    .side-menu ul li ul li { border-top:1px solid #f5f5f5; }
    .side-menu ul li ul li a { padding-left:30px; }

    .side-menu ul li.current ul li.current a { border-right:6px solid #ffd08d; }
    .side-menu ul li ul.a-sub-menu { border-top: 1px solid #f5f5f5; }

    .side-menu .search:before { color:@orange; }
    .side-menu .search input { background-color:#f5f5f5; color:rgba(255,255,255,.75); }

    .sub-menu-slide { background-color: #627789; color: #edf0f2; }
    .sub-menu-slide header { border-bottom-color: #333; background-color: #F6F7F8; color: #1b4461; }
    .sub-menu-slide .sub-menu li { border-bottom-color: #ebebeb; }
    .sub-menu-slide .sub-menu li a { color: #edf0f2; padding: 9px 20px 10px 15px;}
    .sub-menu-slide .sub-menu li a:hover, .sub-menu-slide .sub-menu li a:focus {  background-color:#2a333b; }
}

body .themed.silver.side-menu-collapsed .side-menu ul > li.current > a { padding-left:6px; }
body .themed.silver.side-menu-collapsed .side-menu ul li a { padding: 8px 0 7px 0;}
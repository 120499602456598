@import "a3_variables.less";

#footer { bottom: 0; height: 40px; left: 249px; display: flex; position: fixed; right: 0; }
.footer-nav { float: left; font-size: 90%; list-style: none; margin: 0; padding: 12px 0 11px 20px; }
.footer-nav li { display: inline-block; margin: 0; padding: 0 20px 0 0; }

.side-menu-collapsed #footer { left: 49px; }

.footer-logo, .footer-logo:active, .footer-logo:visited, .footer-logo:hover { float: right; margin: 8px 20px 0 0; opacity: 0.9; font-size:10px; }
.footer-logo:hover { text-decoration:none; }
.footer-logo img { margin-left:10px; }

#footer .system-account-name {
    line-height: 16px;
}

/* responsive */
@media (max-width: 1366px) {
    #footer { left: 250px; }
}

@media (max-width: 850px) {
    #footer { left: 0; position: relative; }
}

body .themed.a3 {
    #footer { border-top: 1px solid #ccc; border-left:none; left: 250px; }
    
    .footer-nav li a { color: @charcoal; }

    .footer-logo, .footer-logo:active, .footer-logo:visited, .footer-logo:hover { color:@charcoal }
}

body .themed.a3.side-menu-collapsed #footer { left: 50px; }
input.amChartsButton { padding: 5px; font-weight: normal; font-size: 12px; }
input.amChartsButtonSelected { padding: 5px;font-size: 12px; }

input.amChartsInputField {background: #ededed; box-shadow: 1px 1px 1px #9aa1a7; border: 1px solid #ededed; border-radius: 0; box-sizing: border-box; }

.amcharts-export-menu .export-main > a, .amcharts-export-menu .export-drawing > a, .amcharts-export-menu .export-delayed-capturing > a { height:30px; width:30px; margin-top:55px; }

.amcharts-pie-slice {
    transform: scale(1);
    transform-origin: 50% 50%;
    transition-duration: 0.3s;
    transition: all .3s ease-out;
    -webkit-transition: all .3s ease-out;
    -moz-transition: all .3s ease-out;
    -o-transition: all .3s ease-out;
    cursor: pointer;
    box-shadow: 0 0 30px 0 #000;
}

.amcharts-pie-slice:hover {
    transform: scale(1.1);
    filter: url(#shadow);
}
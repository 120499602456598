@import 'a3_variables.less';
@import "dark_variables.less";


body .themed.dark {

    .ta-root 
    {
        border-color: transparent;

        .btn:active, 
        .btn:hover, 
        .btn:focus, 
        .btn-default.disabled, 
        .btn-default[disabled], 
        fieldset[disabled] .btn-default, 
        .btn-default.disabled:hover, 
        .btn-default[disabled]:hover,
        fieldset[disabled] .btn-default:hover,
        .btn-default.disabled:focus,
        .btn-default[disabled]:focus,
        fieldset[disabled] .btn-default:focus,
        .btn-default.disabled:active,
        .btn-default[disabled]:active,
        fieldset[disabled] .btn-default:active,
        .btn-default.disabled.active,
        .btn-default[disabled].active,
        fieldset[disabled] .btn-default.active {
            border-color: none;
            color: @dark_primaryButtonTextColor; 
            background-color: @dark_primaryButtonBackgroundColor; 
        }

        .ta-toolbar {
            background-color: @dark_inputBackground;
            border: none;
        }

        .ta-text {
            background-color: @dark_inputBackground !important;
            color: @dark_inputText;
            border: 1px solid @dark_inputBorderColor
        }
    }
}
@import 'a3_variables.less';
@import 'dark_buttons.less';
@import 'dark_variables.less';

body .themed.dark {

    .gridster-loaded .gridster-item { 
        background-color: @dark_widgetBackgroundColor; 
        border-style: solid; 
        border-width: 1px; 
        border-color: @dark_widgetBorderColor;
    }
    
    .gridster-loaded .gridster-item.has-border { 
        border-top: 5px; 
        border-top-style: solid; 
    }
    
    .gridster-loaded .gridster-item.has-border-red {
        border-top-color: @dark_negative; 
    }
    
    .gridster-loaded .gridster-item.has-border-green {
        border-top-color: @dark_positive; 
    }

    .gridster-loaded .gridster-item.has-border-blue {
        border-top-color: @dark_accent1; 
    }

    .gridster-loaded .gridster-item.has-border-orange {
        border-top-color: @dark_orange; 
    }

    .gridster-loaded .gridster-item.has-border-peach {
        border-top-color: @dark_critical; 
    }

    .gridster-loaded .gridster-item.has-border-charcoal {
        border-top-color: @dark_charcoal; 
    }

    .editable-buttons button { 
        border-radius: 0; 
        border-color:transparent; 
        right: 0px; 
    }
    
    .editable-cancel-btn { .dark-white-button }
    .editable-cancel-btn:hover { .dark-white-button-hover }
     
    .gridster-item .dashboard-widget-header .dashboard-widget-hover-options button { 
        border-color:transparent; 
        background-color:transparent;
    }
    
    .gridster-item .value-widget .dashboard-widget-body .label-wrapper h3 {
        background-color:transparent;
    }

    .gridster-item .list-pagination .records-per-page > button { 
        padding:4px; 
    }
    
    .gridster-item .dashboard-widget-header h2 { 
        font-weight: normal; 
    }

    .dashboard-widgets-list .panel>.panel-heading {
        background: transparent;
        border: 1px solid @dark_primaryBackgroundColor;
    }
}
@import 'a3_variables.less';
@import 'dark_buttons.less';
@import 'dark_variables.less';


body .themed.dark {
    color: @dark_primaryFontColor;

    .page { 
        background-color: @dark_primaryBackgroundColor; 
    }
    
    .page-title { 
        border-color: @dark_pageTitleBorderColor; 
        background-color: @dark_pageTitleBackgroundColor; 
        color: @dark_linkColor; 
        font-weight:normal; 
    }
    
    .page-title a { 
        color: @dark_linkColor; 
    }
    
    .page-title header .dropdown { 
        background: transparent; 
    }
    
    .page-title header .dropdown > a { 
        line-height:23px; 
        color: @dark_linkColor; 
        font-weight: normal; 
    }
    
    .page-title header .dropdown > a:hover { 
        text-decoration: none; 
        color:@dark_orange;  
    }
    
    .page-title .dropdown > a > i.fa  { 
        margin-left:5px; 
    }
    
    .page-title button:hover {
        color:@dark_orange; 
        background: @dark_secondaryBackgroundColor; 
        border-color: @dark_primaryBorderColor;
    }
    
    .ng-pageslide { 
        background: @dark_secondaryBackgroundColor; 
        border-left: none;
        box-shadow: @dark_pageSlideBoxShadow; 
        top: 0px !important;
        z-index: 1001 !important;
    }

    .busy-indicator-backdrop {
        background-color: @dark_loadingMaskBackgroundColor;
        opacity: @dark_loadingMaskOpacity;
    }

    .busy-indicator-default-text {
        color: inherit;
    }

    .busy-indicator-default-spinner div {
        background: @dark_loadingMaskSpinnerColor; 
    }

    .busy-indicator-default-sign {
        background: @dark_loadingMaskSignBackgroundColor;
        border-color: @dark_loadingMaskSignBorderColor;
        color: @dark_loadingMaskSignFontColor;
        box-shadow: @dark_loadingMaskBoxShadow;
        text-shadow: none;
    }

    .message-content {
        background-color: #1c2228;
        border: 1px solid #323232;
    }

    .message-medadata {
        color: #999;
    }
}
@import 'a3_variables.less';
@import 'dark_variables.less';


.dark-white-button(){
    background: @dark_whiteButtonBackgroundColor; 
    color: @dark_primaryFontColor; 
    border-color: @dark_whiteButtonBorderColor;
}

.dark-white-button-hover(){
    background-color: @dark_whiteButtonBackgroundColor; 
    border-color: @dark_orange; 
    color: @dark_orange;
}

body .themed.dark {

    /* buttons */
    button, a.button,
    input[type=button],
    input[type=submit] { 
        font-weight: @dark_buttonWeight; 
        border-color:@dark_primaryButtonBorderColor; 
        color:@dark_primaryButtonTextColor; 
        background-color: @dark_primaryButtonBackgroundColor; 
    }

    button:hover, 
    a.button:hover,
    input[type=button]:hover,
    input[type=submit]:hover { 
        background-color: @dark_primaryButtonBackgroundHoverColor; 
        border-color:@dark_primaryButtonBorderHoverColor; 
        color: @dark_primaryButtonTextHoverColor; 
    }
        
    /* button colors */
    button.gray, 
    a.button.gray, 
    input[type=button].gray, 
    input[type=submit].gray,
    button.white, 
    a.button.white, 
    input[type=button].white, 
    input[type=submit].white { 
        .dark-white-button; 
    }
    
    button.gray:hover, 
    a.button.gray:hover, 
    input[type=button].gray:hover, 
    input[type=submit].gray:hover,
    button.white:hover, 
    a.button.white:hover, 
    input[type=button].white:hover, 
    input[type=submit].white:hover { 
        .dark-white-button-hover;  
    }
    
    button.transparent, 
    a.button.transparent, 
    input[type=button].transparent, 
    input[type=submit].transparent { 
        background:transparent; 
        color: @dark_transparentButtonColor; 
        border-color: transparent; 
    }
    
    button.transparent:hover, 
    a.button.transparent:hover, 
    input[type=button].transparent:hover, 
    input[type=submit].transparent:hover { 
        color: @dark_transparentButtonColorHover; 
    }

    /* tag button */
    .tag-button { 
        background-color: @dark_tagButtonBackgroundColor; 
        color: @dark_tagButtonFontColor; 
    }
    
    a.tag {
        color: @dark_tagButtonFontColor;
    }
    
    a.tag:hover {
        color: @dark_tagButtonBackgroundColor; 
    }

    // accordion buttons
    .panel-group .panel .panel-body button {
        color: @dark_accordionButtonColor; 
        background-color: @dark_accordionButtonBackgroundColor; 
        border-color: @dark_accordionButtonBorderColor; 
    }

    .panel-group .panel .panel-body button:hover,
    .panel-group .panel .panel-body button.active {
        color: @dark_orange;
    }

    button:disabled:hover, 
    a.button:disabled:hover,
    input[type=button]:disabled:hover,
    input[type=submit]:disabled:hover { 
        background-color: @dark_disabledButtonBackgroundColorHover; 
        border-color: @dark_disabledButtonColorHover;
    }

    .btn-group  button:first-child, 
    .btn-group  input[type=submit]:first-child, 
    .btn-group  input[type=button]:first-child, 
    .btn-group  a.button:first-child { 
        border-right: none; 
    }

    .tag-container .tag { 
        font-weight: bold;
    }

    .tag-container .tag-form button { 
        background-color: @dark_orange; 
        border-color: @dark_orange; 
        color: @dark_tagButtonFontColor;
    }

    .tag-container .tag button { 
        background-color: @dark_orange; 
        border-color: @dark_orange;
        color: @dark_tagButtonFontColor;
    }
}
    
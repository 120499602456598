@import 'a3_variables.less';
@import 'silver_buttons.less';

body .themed.silver {

    .gridster-loaded .gridster-item { background-color: #f9f9f9; border-style: solid; border-width: 1px; border-color: #e5e6e9 #dfe0e4 #d0d1d5;}
    
    .gridster-loaded .gridster-item.has-border { border-top:2px; border-top-style: solid; }
    .gridster-loaded .gridster-item.has-border-red {border-top-color: #e64a4a; }
    .gridster-loaded .gridster-item.has-border-green {border-top-color: #5b9840; }
    .gridster-loaded .gridster-item.has-border-blue {border-top-color: #428bca; }
    .gridster-loaded .gridster-item.has-border-orange {border-top-color: @orange; }
    .gridster-loaded .gridster-item.has-border-peach {border-top-color: #f8cd95; }
    .gridster-loaded .gridster-item.has-border-charcoal {border-top-color: #333c48; }

    .editable-buttons button { border-radius: 0; border-color:transparent; right:1px; }
    .editable-cancel-btn { .silver-white-button; }
    .editable-cancel-btn:hover { .silver-white-button-hover; }
     
    .gridster-item .dashboard-widget-header .dashboard-widget-hover-options button { border-color:transparent; background-color:transparent;}
    .gridster-item .value-widget .dashboard-widget-body .label-wrapper h3 {background-color:transparent;}
    .gridster-loaded .gridster-item { background-color: #f9f9f9; }

    .gridster-item .list-pagination .records-per-page > button { padding:4px; }
    .gridster-item .dashboard-widget-header h2 { font-weight: normal; }
}
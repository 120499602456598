@import 'a3_variables.less';
@import 'silver_buttons.less';
@import 'silver_variables.less';


body .themed.silver {
    .page { background-color:#fefdfd; }
    .page-title { border-color: rgba(0,0,0,.15); background-color: #627789; color: #fff; font-weight:normal;}
    .page-title a { color: #fff; }
    .page-title header .dropdown > a { line-height:23px; color: #fff; font-weight: normal; }
    .page-title header .dropdown > a:hover { text-decoration: none; color:@orange;  }
    .page-title .dropdown > a > i.fa  { margin-left:5px; }
    .page-title button:hover {color:@orange; background:#627789; border-color:transparent;}
    .page-title > button:last-child {padding-right:0;}


    .ng-pageslide { background: #F6F7F8; border-left: 1px solid #ebebeb;}
}
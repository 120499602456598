@import 'a3_variables.less';
@import 'silver_variables.less';


body .themed.silver {

    /* nested table */
    table table { border-color: #fff; }

    table tr th { background-color:#fff; border-bottom-color: #ededed; color:#627789; }

    table > tbody > tr > td { border-bottom-color:#ebebeb; }
    
    table.group-by-tbody > tbody {
        border-bottom-color: #ebebeb; 
    }

    table thead tr th button { background-color: transparent; color:#627789; border-color: transparent; }
    table thead tr th button:hover { background-color: transparent; border-color:transparent; color:#627789; }

    table button, table a.button { }

    /* table stripes */
    table:not(.manual-stripe) > tbody > tr:nth-child(odd) > td, table > tbody > tr:nth-child(odd) > th { background-color: #fff; }
    table:not(.manual-stripe) > tbody > tr:nth-child(even) > td, table > tbody > tr:nth-child(even) > th { background-color: #f9f9f9; }

    table.manual-stripe > tbody > tr > td { background-color: #fff; }
    table.manual-stripe > tbody > tr.alt > td { background-color: #f9f9f9; }

    // tbody-grouped stripes
    table.group-by-tbody > tbody:nth-of-type(even) > tr > td { background-color: #fff; }
    table.group-by-tbody > tbody:nth-of-type(odd) > tr > td { background-color: #f9f9f9; }

    tr.noRecords span { color: #777; }

    //colapsable rows
    table tr.table-details-row .bond-grid-details-row { border-color: #ededed; }
    table tr.clickable-table-row:hover td.clickable-table-cell i { color: @orange;}

    table tr.alt.table-details-row .bond-grid-details-row {border-color: #f9f9f9;}

    
    table tr td.table-row-status-critical {
        border-left: 1rem solid @red;
    }
    
    table tr td.table-row-status-warning {
        border-left: 1rem solid @warningOrange;
    }
    
    table tr td.table-row-status-attention {
        border-left: 1rem solid @orange;
    }


    .email-preview { 
        border: 1px solid #ebebeb; 
    }
    
    .email-preview table {
        border: none; 
        background: #fff !important; 
    }
    
    .email-preview table tr > td {
        background: #fff !important; 
    }
}
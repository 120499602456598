/* please don't change 'position' */
/* there must be 'bottom' for ps-scrollbar-x-rail */
.ps-container .ps-scrollbar-x-rail { position: absolute; bottom: 3px; height: 8px; -webkit-border-radius: 4px; -moz-border-radius: 4px; border-radius: 4px; opacity: 0; -o-transition: background-color .2s linear, opacity .2s linear; -webkit-transition: background-color .2s linear, opacity .2s linear; -moz-transition: background-color .2s linear, opacity .2s linear; transition: background-color .2s linear, opacity .2s linear; }

.ps-container:hover .ps-scrollbar-x-rail,
.ps-container.hover .ps-scrollbar-x-rail { opacity: 0.6; }

.ps-container .ps-scrollbar-x-rail:hover,
.ps-container .ps-scrollbar-x-rail.hover { background-color: #eee; opacity: 0.9; }

.ps-container .ps-scrollbar-x-rail.in-scrolling { background-color: #eee; opacity: 0.9; }

/* please don't change 'position' */
/* there must be 'right' for ps-scrollbar-y-rail */
.ps-container .ps-scrollbar-y-rail { position: absolute; right: 3px; width: 8px; -webkit-border-radius: 4px; -moz-border-radius: 4px; border-radius: 4px; opacity: 0; -o-transition: background-color .2s linear, opacity .2s linear; -webkit-transition: background-color .2s linear, opacity .2s linear; -moz-transition: background-color .2s linear, opacity .2s linear; transition: background-color .2s linear, opacity .2s linear; }

.ps-container:hover .ps-scrollbar-y-rail,
.ps-container.hover .ps-scrollbar-y-rail { opacity: 0.6; }

.ps-container .ps-scrollbar-y-rail:hover,
.ps-container .ps-scrollbar-y-rail.hover { background-color: #eee; opacity: 0.9; }

.ps-container .ps-scrollbar-y-rail.in-scrolling { background-color: #eee; opacity: 0.9; }

/* please don't change 'position' */
/* there must be 'bottom' for ps-scrollbar-x */
.ps-container .ps-scrollbar-x { position: absolute; bottom: 0; height: 8px; background-color: #aaa; -webkit-border-radius: 4px; -moz-border-radius: 4px; border-radius: 4px; -o-transition: background-color .2s linear; -webkit-transition: background-color.2s linear; -moz-transition: background-color .2s linear;transition: background-color .2s linear; }

/* fixed scrollbar height in xp sp3 ie6 */
.ps-container.ie6 .ps-scrollbar-x { font-size: 0; }

.ps-container .ps-scrollbar-x-rail:hover .ps-scrollbar-x,
.ps-container .ps-scrollbar-x-rail.hover .ps-scrollbar-x { background-color: #999; }

/* please don't change 'position' */
/* there must be 'right' for ps-scrollbar-y */
.ps-container .ps-scrollbar-y { position: absolute; right: 0; width: 8px; background-color: #aaa; -webkit-border-radius: 4px; -moz-border-radius: 4px; border-radius: 4px; -o-transition: background-color .2s linear; -webkit-transition: background-color.2s linear; -moz-transition: background-color .2s linear; transition: background-color .2s linear; }

/* fixed scrollbar height in xp sp3 ie6 */
.ps-container.ie6 .ps-scrollbar-y { font-size: 0; }

.ps-container .ps-scrollbar-y-rail:hover .ps-scrollbar-y,
.ps-container .ps-scrollbar-y-rail.hover .ps-scrollbar-y { background-color: #999; }

.ps-container.ie .ps-scrollbar-x,
.ps-container.ie .ps-scrollbar-y { visibility: hidden; }

.ps-container.ie:hover .ps-scrollbar-x,
.ps-container.ie:hover .ps-scrollbar-y,
.ps-container.ie.hover .ps-scrollbar-x,
.ps-container.ie.hover .ps-scrollbar-y { visibility: visible; }

@import "a3_variables.less";
@import "a3_mixins.less";

@Background: #F5F5F5;
@TopButtonBackground: #F5F5F5;
@FormFieldDivBackground: #F5F5F5;
@RightInnerDivColor: #F5F5F5;
@baseFontSize: 12px;

.editor-loading-mask { background-color: @gray; width:100%; height:100%; position:absolute; left:0; top:0; }
.editor-loading-mask > div { width: 500px; height: 365px; position: absolute; top: 50%; left: 50%; margin: -250px; text-align: center; }
.editor-loading-mask > div > img {width:250px;}
.editor-loading-mask p { font-weight:bold; }
.editor-loading-mask p img { width:35px; }

.btn-formfield { font-weight: normal !important; background-color: @charcoal !important; color: @tabGray !important; padding: 10px; padding-left: 15px; padding-right: 15px; box-shadow: none;}
.btn-formfield.btn-xlg { width: 100%; padding: 20px 0; font-size: @baseFontSize + 5px !important; }

.document-editor-frame {border:none; height:100%; width:100%; display:block;}

.document-editor { background-color: @Background; height: 100%; }
.document-editor form {margin:0;}

.document-editor .docedit-top { position:relative; overflow:hidden; height:70px; white-space: nowrap; overflow-x: hidden; background-color: @charcoal; padding:15px 55px; border-bottom: 1px @TopButtonBackground solid;}
.document-editor .docedit-top a:focus { text-decoration: none; }
.document-editor .docedit-top .placeholder-categories-container { z-index: 1000;  position:relative; height: 50px; overflow-x: hidden; overflow-y: visible;}
.document-editor .docedit-top .placeholder-categories-container .btn { cursor:pointer; .btn-formfield; background-color: @TopButtonBackground !important; }
.document-editor .docedit-top .placeholder-categories-container .btn.current {  }
.document-editor .docedit-top .placeholder-categories-container .btn.current:hover { background-color: #ffa400 !important; border: 1px solid #f59d00 !important; color: #fff !important; }

.document-editor .docedit-top .placeholder-categories-container .horizontal-scroll { position: absolute; left: 0;}
.document-editor .docedit-top .scroll-button-left { color: @TopButtonBackground; cursor:pointer; z-index:1000; position: absolute; left: 0; top:0; padding:10px 10px 5px 10px; height:69px; background-color: @charcoal;}
.document-editor .docedit-top .scroll-button-right {color: @TopButtonBackground;  cursor:pointer; z-index:1000; position: absolute; right: 0; top:0; padding:10px 10px 5px 10px; height:69px; background-color: @charcoal;}
 
.document-editor .left-pane {padding-top:20px;}
.document-editor .left-pane .scroller {height: ~'calc(100% - 350px)';}
.document-editor .left-pane .navigation-explanation {height:110px;}

.document-editor .docedit-left { height:100%; position:relative; padding:0; }
.document-editor .formfield { padding-bottom: 30px !important; }
.document-editor .form-field-container {width:100%; height: 100%; padding: 70px 0 50px 0; position:absolute; top:0; padding-bottom:155px;}

.document-editor .docedit-bottom { background-color: @charcoal; border-top: 1px @TopButtonBackground solid; padding: 20px; position: absolute; left: 0; right: 0; bottom: 0; }
.document-editor .docedit-bottom div.input-group { background-color: @Background; color: @tabGray; border-radius: 5px; padding-top: 10px !important; padding-bottom: 10px !important; padding-left: 20px !important; padding-right: 20px !important; }
.document-editor .docedit-bottom input.btn-block { background-color: transparent; font-size: @baseFontSize + 3px !important; border: 0; }
.document-editor .docedit-bottom .icon-search { color: @orange; font-size:18px;}
.document-editor .docedit-bottom .pull-right { text-align: right; padding-top: 20px; }
.document-editor .docedit-bottom a.input-group-addon { background-color: transparent; border: 0; }

.document-editor .docedit-right { background-color: #F5F5F5; height:100%; padding:0; }
.document-editor .docedit-right .top { background-color:@white; padding:20px; box-shadow:@boxShadows; }
.document-editor .docedit-right .top .form-group { margin:0; background-color: #f5f5f5; border-radius:5px; padding:10px; }
.document-editor .docedit-right .top .form-group > div {z-index:1000;}
.document-editor .docedit-right .top .form-group .form-control {height:32px;  box-shadow:@boxShadows; border-radius:5px; border:1px solid #dedede;}
.document-editor .docedit-right .top .form-group .form-control:focus,
.document-editor .docedit-right .top .form-group .form-control:active {box-shadow: 0 1px 0 #9aa1a7 !important}
.document-editor .docedit-right .top .form-group .dropdown,
.document-editor .docedit-right .top .form-group .dropdown a {width:100%; height:32px;}
.document-editor .docedit-right .top .form-group .dropdown .a-group:last-child {height:30px; position:absolute; right:0; padding-top:9px; }

.document-editor .editor-container {height: ~'calc(100% - 35px)'; width: 100%; position: absolute; top: 35px; left: 0; }
.document-editor .editor-container iframe {height: 100%; width: 100%; border:none; }

.document-editor .button-container { padding-top: 10px; }
.document-editor .button-container .btn { margin-left: 10px; box-shadow:none; }

.document-editor .padding-top-10px { padding-top:10px; }

.document-editor .dark-orange { box-shadow: none;}
.document-editor .input-group-addon:hover {color: @orange;}

.document-editor .tabs .nav li { background-color: #ededed; }
.document-editor .tabs .nav li a { border: none; margin: 0; }

.field-draggable-wrapper {text-align:left;}
.field-draggable-wrapper h4 {margin:0; font-size:14px;}
.field-draggable-container {width: ~'calc(50% - 9px)'; display: inline-block; margin: 3px 3px 3px 0; text-align:left;}
.field-draggable { font-size:10px; background-color: #ccffcc; padding:3px;}
.field-draggable.image { width:75px; height:75px;}
.field-draggable.check-box { width:20px; padding-left:20px;}

.document-editor .editor-controls {padding:5px 20px 5px 0px;}
.document-editor .editor-controls button.small {height:24px;}

.document-editor [class*="col-"] section { margin-bottom:0; }
@ngt-module: ~'ng-toast';
@ngt-spacing: 20px;
@ngt-topSpacing: 0px;

.@{ngt-module} {
  position: fixed;
  z-index: 1080;
  width: 100%;
  height: 0;
  margin-top: @ngt-topSpacing;
  text-align: center;

  &.@{ngt-module}--top {
    top: 40px;
    bottom: auto;

    .@{ngt-module}__list {
      top: 0;
      bottom: auto;
      width:70%;
      margin-left:250px;
    }

    &.@{ngt-module}--center {

      .@{ngt-module}__list {
        position: static;
      }
    }
  }

  &.@{ngt-module}--bottom {
    top: auto;
    bottom: 0;

    .@{ngt-module}__list {
      top: auto;
      bottom: 0;
    }

    &.@{ngt-module}--center {

      .@{ngt-module}__list {
        pointer-events: none;
      }

      .@{ngt-module}__message {
        .alert {
          pointer-events: auto;
        }
      }
    }
  }

  &.@{ngt-module}--right {

    .@{ngt-module}__list {
      left: auto;
      right: 0;
      margin-right: @ngt-spacing;
    }

    .@{ngt-module}__message {
      text-align: right;
    }
  }

  &.@{ngt-module}--left {

    .@{ngt-module}__list {
      right: auto;
      left: 0;
      margin-left: @ngt-spacing;
    }

    .@{ngt-module}__message {
      text-align: left;
    }
  }

  .@{ngt-module}__list {
    display: inline-block;
    position: absolute;
    right: 0;
    left: 0;
    margin: 0 auto;
    padding: 0;
    list-style: none;
  }

  .@{ngt-module}__message {
    display: block;
    width: 100%;
    text-align: center;

    .alert {
      display: inline-block;
      width:100%;
    }
  }

  .ng-enter,
  .ng-leave,
  .ng-move {
    transition: 0.3s ease left, 0.3s ease opacity;
  }
  .ng-enter {
    left:-50px;
    opacity:0;
  }
  .ng-enter.ng-enter-active {
    left:0;
    opacity:1;
  }
  .ng-leave {
    left:0;
    opacity:1;
  }
  .ng-leave.ng-leave-active {
    left:-50px;
    opacity:0;
  }
  .ng-move {
    opacity:0.5;
  }
  .ng-move.ng-move-active {
    opacity:1;
  }
}

.ng-toast .alert {color:#fff;  border-radius: 10px;  font-size:14px; font-weight:bold;}
.ng-toast .alert-success { background-color:@charcoal;}
.ng-toast .alert-danger { background-color:@warningOrange;}

@media (max-width: 991px) {
    .ng-toast__list { margin-left:180px !important; }
}

@media (max-width: 767px) {
    .ng-toast .alert {text-align:left; border-radius:0; opacity:.9;}
    .ng-toast { width:100% !important; top:0 !important; left:0 !important; position:fixed; }
    .ng-toast__list { margin-left:0 !important; width:100% !important; }
}
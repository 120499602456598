@import 'a3_variables.less';
@import 'dark_variables.less';

body .themed.dark {

    /* nested table */
    table table { 
        border-color: @dark_tableBorderColor; 
        color: @dark_primaryFontColor; 
    }

    table tr th { 
        background-color: @dark_tableHeaderBackgroundColor;  
        border-bottom-color: 1px solid @dark_tableRowBorderColor; 
        color:@dark_tableHeaderFontColor; 
    }

    table > tbody > tr > td { 
        border-bottom-color: @dark_tableRowBorderColor; 
    }

    table.group-by-tbody > tbody {
        border-bottom-color: @dark_tableRowBorderColor; 
    }

    table thead tr th button { 
        background-color: @dark_tableHeaderButtonBackgroundColor; 
        color: @dark_tableHeaderButtonFontColor; 
        border-color: @dark_tableHeaderButtonBorderColor; 
    }
    
    table thead tr th button:hover { 
        background-color: @dark_tableHeaderButtonBackgroundColorHover; 
        border-color: @dark_tableHeaderButtonBorderColor; 
        color: @dark_tableHeaderButtonFontColorHover; 
    }

    table button, table a.button { }

    /* table stripes */
    table:not(.manual-stripe) > tbody > tr:nth-child(odd) > td, 
    table > tbody > tr:nth-child(odd) > th,
    table.manual-stripe > tbody > tr > td,
    table.group-by-tbody > tbody:nth-of-type(odd) > tr > td { 
        background-color: @dark_tableOddRowBackgroundColor; 
    }
    table:not(.manual-stripe) > tbody > tr:nth-child(even) > td, 
    table > tbody > tr:nth-child(even) > th,
    table.manual-stripe > tbody > tr.alt > td,
    table.group-by-tbody > tbody:nth-of-type(even) > tr > td  { 
        background-color: @dark_tableEvenRowBackgroundColor; 
    }
    
    tr.noRecords span { 
        color: @dark_primaryFontColor; 
    }

    //colapsable rows
    table tr.table-details-row .bond-grid-details-row { 
        border-color: @dark_tableCollapsableRowBorderColor; 
    }
    
    table tr.clickable-table-row:hover td.clickable-table-cell i { 
        color: @dark_tableClickableRowHoverColor; 
    }

    table tr.alt.table-details-row .bond-grid-details-row {
        border-color: @dark_tableCollapsableRowBorderColor;
    }

    .initial-state .empty-backdrop-grad { 
        background: none;
    }

    .initial-state,
    .initial-state.white {
        border-color: transparent;
        background-color: transparent;
    }

    .initial-state .icon {
        background-color: @dark_emptyStateIconBackgroundColor;
    }

    .initial-state .icon i {
        color: @dark_emptyStateIconColor;
        opacity: @dark_emptyStateIconOpacity;
    }

    table tr td.table-row-status-critical {
        border-left: 1rem solid @dark_indication1;
    }
    
    table tr td.table-row-status-warning {
        border-left: 1rem solid @dark_indication2;
    }
    
    table tr td.table-row-status-attention {
        border-left: 1rem solid @dark_indication3;
    }

    .email-preview { 
        border: 1px solid @dark_inputBorderColor; 
    }
    
    .email-preview table {
        border: none; 
        background: @dark_tableOddRowBackgroundColor !important; 
    }
    
    .email-preview table tr > td {
        background: @dark_tableOddRowBackgroundColor !important; 
    }
}
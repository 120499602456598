@import 'a3_variables.less';

html.indemnityLanding, .indemnityLanding body { width:100%; min-height:100%; margin:0; padding:0; /*background:@charcoal;*/ }
html.indemnityLanding form, html.indemnityLanding .col-md-8 { height:100%; }

.indemnityLanding .clearfix { min-height:100%; overflow:auto; zoom:1; }

.indemnityLanding p { color:@charcoal; }
.indemnityLanding ul { margin:5px 0; padding:0; list-style:none; }
.indemnityLanding ul li { font-size:14px; color:@charcoal; }
.indemnityLanding ul li:before { content:""; width:7px; height:7px; margin-right:10px; display:inline-block; border-radius:5px; background:@orange; }
.indemnityLanding p .center { margin-top:30px; }
.indemnityLanding .center { text-align:center; font-size:15px; }

.indemnityLanding input[type=checkbox] { margin-right:10px; }

.indemnityLanding .checkbox { margin-top:30px; }

.indemnityLanding .fields { text-align:center; margin-top:30px; }
.indemnityLanding .fields p { display:inline-block; font-size:18px; line-height:40px; }
                  
.indemnityLanding .indemnity-page { min-height:100%; margin:0 auto; padding:20px; box-sizing:border-box; background:#fff; }

/* header */
.indemnityLanding .indemnity-page > header { padding:0 20px; text-align:right; }
.indemnityLanding .indemnity-page > header img { max-height:35px; margin-bottom:20px; display:inline-block; }
.indemnityLanding .indemnity-page > header h4 { float:left; font-weight:bold; }
                  
.indemnityLanding section .contract { width:100%; max-height:300px; border:1px solid rgba(0,0,0,.15); border-radius:5px; overflow-y:auto; }
.indemnityLanding section header h4 { font-size:13px; color:@charcoal; font-weight:bold; }
.indemnityLanding section .contract > .body { padding:20px; margin-top:0; margin-bottom:30px;}
.indemnityLanding .term-list { margin-top:20px;}

/* terms & conditions */
.indemnityLanding .center { width:400px; margin:0 auto; text-align:left; }

/* signed */
.indemnityLanding h1 { font-size:28px; color:@green; font-weight:bold; text-align:center; }
.indemnityLanding i.green { padding-bottom:15px; color:@green; text-align:center; }
.indemnityLanding p { text-align:center; }

/* input */
.indemnityLanding .indemnity-page [type=text] { width:100%; margin-bottom:6px; padding:12px 16px; font-size:14px; border:2px solid #ddd; box-shadow:none; border-radius:4px; box-sizing:border-box; }
.indemnityLanding .signature { text-align:center; font-weight:bold; margin-top:30px; }

/* field-wrapper */
.indemnityLanding .field-wrapper { position:relative; }
.indemnityLanding .field-wrapper div.label { padding:2px 2px 4px 4px; position:absolute; top:0; right:0; font-size:11px; border-radius:0 0 0 4px; color:#fff; }
.indemnityLanding .field-wrapper[class*='col-'] div.label { right: 15px; }
.indemnityLanding .field-wrapper .validation-indicator { color:@orange; }
.indemnityLanding .field-wrapper input:not([type=checkbox]):not([type=radio]) , .indemnityLanding  .field-wrapper select { background-color:#fff; -webkit-appearance: none; -moz-appearance: none; appearance: none; }
.indemnityLanding .field-wrapper .ui-select-container .ui-select-match .caret { display: none; }
.indemnityLanding .field-wrapper label + label {margin-left:10px;}

/*buttons*/

.indemnityLanding button { padding:14px 24px; font-size:14px; font-weight:bold; border:none; border-radius:4px; background:#009eef; color:#fff; cursor:pointer; }
.indemnityLanding button + button { margin-left:4px; }
.indemnityLanding .a-btn { width:initial; padding:14px 24px; }
.indemnityLanding section + button { margin-top:20px; float:left; }

/* footer */
.indemnityLanding footer { width:100%; height:44px; border-top:1px solid #ccc; background:#ddd; }
.indemnityLanding footer .logo { width:84px; height:100%; margin-right:20px; float:right; display:inline-block; vertical-align:middle; background:url(../../resources/img/icons/a3_footer_logo.png) no-repeat right; background-size:80%; }

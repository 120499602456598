@import 'a3_variables.less';

#supportModal {
    display: flex;
    flex-direction: column;
    min-height: 550px;
    height: 75vh;

    & > .header { }

    .tabs { display: flex; flex-direction: column; flex: 1; margin: 0; }
    .tabs .flex { flex: 1; height:100%; }
    .tabs .tab-content { height: ~"calc(100% - 115px)"; margin-top:10px; }
    .tabs .tab-content .tab-pane { flex-direction: column; height: 100%; }
    .tabs .tab-content .tab-pane.active { display: flex; }
    .tabs .tab-content .tab-pane section { padding: 10px; }

    section { padding-bottom: 10px; }

    // ticket selected
    
    .tabs .tab-content.open .ticket-body.open { position: absolute; top: 80px; left: 12px; bottom: 145px; background: white; right: 12px; border-top:1px solid #d0d3d5;}
    .tabs .tab-content.open { height:  ~"calc(100% - 10px)"; position:relative; }
    .tabs .tab-content.open .ticket footer { position: absolute; width: 100%; bottom: 0; left: 0; }
    .tabs .tab-content.open .ticket .reply { position:absolute; width:100%; bottom:48px; left:0; padding-right:0px; }
    .tabs .tab-content.open .ticket .reply .response {padding-right:10px; min-height:0; height:55px;}
    .tabs .tab-content.open .ticket .reply .response article > div {border:none;}
    .tabs .tab-content.open .ticket .reply .button-container { text-align: right; padding-right:10px; }

    // empty/initial state
    .tabs .tab-content .tab-pane .initial-state { height: 100%; }

    .tickets-list-filter .select {margin-top:10px;}

    .tickets-list { flex: 1; overflow: hidden; }
    .ticket { background: @white; border-radius: @baseBorderRadius; }

    support-ticket {margin-top:10px; display:block;}
    support-ticket:first-of-type { margin: 0; }

    //header
    .ticket-list-header { margin-bottom:10px; display:table; width:100%;}
    .ticket-list-header span {text-align:left; display: table-cell; vertical-align: bottom; }
    
    .ticket header { min-height: 39px; padding: 0; position: relative; }
    .ticket header .ticket-number { color: @lightFontColor; float: left; line-height: 1em; padding: 16px 10px 6px 10px; width: 20%; }
    .ticket header .ticket-title { float: left; font-weight: 700; line-height: 1em; margin: 0; padding: 12px 10px 6px 10px; width: 80%; }
    .ticket header .ticket-title .counter { color: @lightFontColor; display: inline-block; font-size: 75%; line-height: 1em; margin-left: 5px; }
    .ticket header .ticket-title .counter.has-new-message { color: @red; }
    .ticket header .ticket-status { bottom: 5px; font-weight: 700; padding: 2px 10px; position: absolute; text-align: center; text-transform: uppercase; width: 20%; }
    .ticket header .ticket-status.new { background: @red; color: @white; }
    .ticket header .ticket-status.in-progress { background: @translucentBlue; color: @white; }

    //body
    .ticket .ticket-body {}
    .ticket .ticket-description { overflow: hidden; padding: 6px 10px 6px 10px; padding-left: ~"calc(20% + 10px)"; border-bottom:1px solid #d0d3d5; clear:both;}
    .ticket .ticket-description .closed-description { margin-bottom: 10px; }
    .ticket .ticket-description .closed-description > span { display: inline-block; }
    .ticket .ticket-description .closed-description > span:last-of-type { color: @darkGray; }
    .ticket .ticket-description .closed-description p { margin: 0; }
    .ticket .message-thread { margin: 20px 0; }
    .ticket .message-thread .button-container { padding: 0 20px; position: relative; }
    .ticket .response { clear: both; margin-bottom: 1px; min-height: 2em; overflow: hidden; padding: 0 20px 0 10px; text-align: left; }
    .ticket .response aside { float: left; padding-right: 10px; padding-top: 16px; text-align: right; width: 20%; }
    .ticket .response aside img { max-height: 2em; max-width: 2em; }
    .ticket .response aside .user-avatar { display: inline-block; }
    .ticket .response:not(.support-team-response) aside .avatar-img  { border-radius: 50%; }
    .ticket .response aside .user-avatar .avatar-img { margin-right: 0; }
    .ticket .response article { float: right; padding-left: 10px; width: 80%; }
    .ticket .response article .field-wrapper { margin-top: 0; }
    .ticket .response article time { color: @lightFontColor; font-size: 85%; text-transform: uppercase; }
    .ticket .response article > div:first-of-type { border-bottom: 1px solid @gray; border-top: 1px solid @gray; margin: 0; padding-bottom: 15px; padding-top: 15px; }
    .ticket .response.support-team-response article { font-weight: 700; }
    .ticket .response:first-of-type aside { padding-top: 0; }
    .ticket .response:first-of-type article > div:first-of-type { border-top: none; padding-top: 0; }
    .ticket .response.reply article > div:first-of-type { border-bottom: none; }
    .ticket .response.last + .button-container { background: @white; margin-top: -2px; }

    //footer
    .ticket footer { background: @gray; border-bottom-left-radius: @baseBorderRadius; border-bottom-right-radius: @baseBorderRadius; overflow: hidden; padding: 0; position: relative; text-align: left; }
    .ticket footer .ticket-meta { border-right: 1px solid @tabGray; float: left; padding: 6px 10px; height:38px; }
    .ticket footer .ticket-meta.created-date { width: 20%; }
    .ticket footer .ticket-meta.created-by { width: 25%; }
    .ticket footer .ticket-meta.assigned-to { width: 25%; }
    .ticket footer .ticket-meta.priority { border-right: none; width: 15%; }
    .ticket footer .ticket-meta.priority .critical { color: @red; font-weight: 700; }
    .ticket footer .ticket-meta span.ticket-meta-label { color: @darkGray; display: block; font-size: 90%; }
    .ticket footer .ticket-meta p { line-height: 1em; margin: 0; }
    .ticket footer button { bottom: 6px; position: absolute; right: 10px; }

    //open ticket styles
    .ticket.open { margin: 0;}
    .ticket.open .ticket-body { margin: 0; }
    .ticket.open .ticket-description { border-bottom: 1px solid @tabGray; height: auto; }
   
}

 body .themed.a3 #supportModal{
}

body .themed.silver #supportModal{
    .tab-content { border-top: 1px solid #ebebeb; }
    .tab-pane { background: #F6F7F8; }
}
@import 'a3_variables.less';

// initial state
.initial-state { color: @placeholderTextColor; font-size: @mainFontSize; overflow: hidden; padding: 30px; position: relative; text-align: center;  border: 1px solid #ebebeb; background-color:#fff; }
.initial-state.borderless { border: none; }
.dashboard .initial-state { padding: 25px 20px; }
.tab-pane .initial-state { background: #fafafa; }
.initial-state.white, .tab-pane .initial-state.white { background: @white; border: 1px solid #f0f0f0; }
.initial-state p { margin: 10px 0; }

.initial-state .initial-state-body { margin: 0 auto; max-width: 450px; position: relative; z-index: 1; }
.initial-state .icon { background: @tabGray; border-radius: 50%; color: @white; height: 88px; margin: 0 auto 20px auto; text-align: center; width: 88px; }
.initial-state .icon i { font-size: 50px; line-height: 88px; text-shadow: 1px 1px 3px rgba(0,0,0,0.2); }
.initial-state .empty-backdrop { left: 0; opacity: 0.5; pointer-events: none; position: absolute; top: 0; width: 100%; z-index: 0; }
.initial-state .empty-backdrop-grad { background: -moz-linear-gradient(top,  rgba(250,250,250,0) 0%, rgba(250,250,250,1) 100%); /* FF3.6-15 */ background: -webkit-linear-gradient(top,  rgba(250,250,250,0) 0%,rgba(250,250,250,1) 100%); /* Chrome10-25,Safari5.1-6 */ background: linear-gradient(to bottom,  rgba(250,250,250,0) 0%,rgba(250,250,250,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ bottom: 0; height: 100%; left: 0; position: absolute; right: 0; top: 0; width: 100%; }
.dashboard .initial-state .empty-backdrop-grad { background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */ background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */ background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }

// empty state
.empty-state { color: @placeholderTextColor; font-size: @mainFontSize; padding: 30px; text-align: center; }
.dashboard .empty-state { padding: 25px 20px; }
.empty-state i { line-height: 88px; }
.empty-state i.fa-thumbs-o-up { color: #57a75b; font-size: 74px; margin-bottom: 20px; }
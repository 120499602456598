@import 'a3_variables.less';

body .themed {

    .dashboard .dashboard-auto-dashboard-dropdown {
        min-width:130px;
    }

    .dashboard-widget-wrapper {
        position: absolute;
        width: 100%;
    }

    .dashboard-widget-wrapper > div {
        display: box;
        display: flexbox;
        display: flex;
        flex-flow: column;
        height: 100%;
        width: 100%;
    }

    .dashboard .gridster {
        margin: 0 10px;
    }

    .gridster-item {
        border: 1px solid @gray;
        border-radius: @baseBorderRadius;
        padding-top: 2px;
    }

    .gridster-item .dashboard-widget-header {
        height: 3.5rem;
        border-bottom: 1px solid @translucentBorder;
        cursor: move;
        width: 100%;
        display: flex;
    }

    .gridster-item .dashboard-widget-header h2 {
        font-size: 14px;
        font-weight: bold;
        margin: auto 0;
        padding: 0px 10px;
    }

    .gridster-item .dashboard-widget-header .dashboard-widget-hover-options {
        margin-left: auto;
        display: none;
    }

    .gridster-item .dashboard-widget-header .dashboard-widget-hover-options .dropdown {
        display: flex;
    }

    .gridster-item .dashboard-widget-header .dashboard-widget-hover-options .dropdown > button,
    .gridster-item .dashboard-widget-header .dashboard-widget-hover-options > button {
        padding: .5rem;
        background: @white;
        border-radius: 0;
        box-shadow: none;
        color: @solidBorder;
    }

    .gridster-item .dashboard-widget-header .dashboard-widget-hover-options .dropdown button span {
        display: inline-block;
        font-weight: normal;
        margin-left: 0;
    }

    .gridster-item .dashboard-widget-header .dashboard-widget-hover-options .dropdown.open button {
        color: @primaryFontColor;
    }

    .gridster-item .dashboard-widget-header .dashboard-widget-hover-options .dropdown .tooltip-inner {
        min-width: 60px;
        width: auto;
    }

    .gridster-item .dashboard-widget-header .dashboard-widget-hover-options button:hover {
        color: @lightFontColor;
    }

    .gridster-item .dashboard-widget-header .dashboard-widget-hover-options button + button {
        margin: 0;
    }

    .gridster-item .dashboard-widget-header .dashboard-widget-hover-options button i {
        margin: 0;
    }

    .gridster-item:hover {
        border-color: @translucentBorder;
        z-index: 10;
    }

    .gridster .gridster-item:hover .handle-se {
        background: url(/resources/img/icons/resize_handle.png) 100% 100% no-repeat;
        border: 0;
        bottom: 2px;
        height: 12px;
        width: 12px;
    }

    .gridster-item:hover .dashboard-widget-header .dashboard-widget-hover-options {
        display: flex;
        padding-right: .5rem;
    }

    .gridster-item .dashboard-widget-body {
        align-self: stretch;
        flex: 1;
        order: 2;
        position: relative;
        width: 100%;
    }

    .gridster-item .dashboard-widget-body > div {
        height: 100%;
        padding: 10px 10px 5px 10px;
        position: absolute;
        width: 100%;
    }

    .gridster-item .dashboard-widget-body .standard-widget {
        height: 100%;
    }

    .gridster-item .dashboard-widget-body .standard-widget .list-wrapper.scroller {
        height: ~"calc(100% - 70px)";
    }

    .gridster-item .dashboard-widget-body > div .meta-bar + .scroller {
        height: ~"calc(100% - 14px)";
    }

    .gridster-item .dashboard-widget-body > div .scroller.no-results {
        height: ~"calc(100% - 20px)";
        top: 20px;
        z-index: 0;
    }

    .gridster-item .dashboard-widget-body .widget-body-content {
        height: 100%;
    }

    .gridster-item-resizable-handler:not(.handle-se) {
        cursor: default;
        pointer-events: none;
    }


    // Dashboard Widget Picker Modal
    .dashboard-widget-picker {
        border: 1px solid @translucentBorder;
        border-radius: @baseBorderRadius;
        height: 425px;
        overflow: hidden;
    }

    .dashboard-widget-picker > div {
        margin: 0;
    }

    .dashboard-widget-pane {
        height: 100%;
        padding: 0;
    }

    .dashboard-widget-pane .scroller > div:first-child {
        padding: 0 15px 15px;
    }

    /* adds padding around content only and not scrollbar divs */
    .dashboard-widget-pane h5 {
        color: inherit;
    }

    //.dashboard-widget-pane button {
    //    margin-bottom: 15px;
    //}

    .dashboard-widgets-list {
        background: @translucentBorder;
        height: 100%;
        padding: 0 1px 0 0;
    }

    .dashboard-widgets-list .panel-group {
        margin: 0;
    }

    .dashboard-widgets-list .panel {
        background: transparent;
        border: 0;
        border-radius: 0;
        box-shadow: none;
        margin-bottom: 1px;
        overflow: initial;
    }

    .dashboard-widgets-list .panel-group .panel + .panel {
        margin: 0 0 1px 0;
    }

    .dashboard-widgets-list .panel > .panel-heading {
        background: @white;
        border: 0;
        border-radius: 0;
        color: @primaryFontColor;
        font-size: 15px;
        height: 56px;
        padding: 0;
    }

    .dashboard-widgets-list .panel > .panel-heading h4 {
        display: block;
        font-weight: bold;
        line-height: 56px;
    }

    .dashboard-widgets-list .panel > .panel-heading h4 a {
        display: block;
        padding: 0 20px;
        position: relative;
        text-decoration: none;
    }

    .dashboard-widgets-list .panel > .panel-heading h4 a i {
        box-shadow: none;
        float: right;
        line-height: 56px;
    }

    .dashboard-widgets-list .panel > .panel-heading h4 a em {
        font-style: normal;
        font-weight: normal;
    }

    .dashboard-widgets-list .panel > .panel-heading h4 a:hover,
    .db-widgets-list .panel > .panel-heading h4 a:focus {
        text-decoration: none;
    }

    // Widget List
    .dashboard-widgets-list .panel > .panel-heading + .panel-collapse .panel-body {
        background: transparent;
        border: 0;
        padding: 6px 4px;
    }

    .dashboard-widgets-list .panel-body button {
        background: @white;
        border: 1px solid @white;
        box-shadow: none;
        color: @primaryFontColor;
        font-size: 13px;
        font-weight: normal;
        line-height: 1em;
        margin: 2px 0;
        padding: 12px 15px;
        position: relative;
        text-align: left;
        width: 100%;
    }

    .dashboard-widgets-list .panel-body button small {
        color: @darkGray;
        font-size: 12px;
    }

    .dashboard-widgets-list .panel-body button + button {
        margin-left: 0;
    }

    .dashboard-widgets-list .panel-body button img {
        height: 32px;
        width: 32px;
    }

    .dashboard-widgets-list .panel-body button i {
        color: @darkGray;
        display: inline-block;
        font-size: 24px;
        height: 32px;
        line-height: 32px;
        text-align: center;
        vertical-align: middle;
        width: 32px;
    }

    .dashboard-widgets-list .panel-body button span {
        display: inline-block;
        vertical-align: middle;
    }

    .dashboard-widgets-list .panel-body button.active {
        border-color: @orange;
    }


    // Widget View Pane
    .dashboard-widget-pane h3 {
        font-size: 14px;
        font-weight: normal;
        text-align: center;
    }
    // ideally we won't want to create a modified heading for this. We would want to select from a predefined set of headings (h1 - h6)
    .dashboard-widget-pane img {
        display: block;
        margin: 15px auto 30px auto;
    }

    .dashboard-widget-pane .tag-container {
        margin: 30px 0 15px 0;
    }

    // Table Elements
    .dashboard-widget-wrapper table > thead > tr > th,
    .dashboard-widget-wrapper table > tbody > tr > td {
        padding: 5px;
    }

    // Pagination
    .dashboard-widget-wrapper .list-pagination {
        margin-bottom: 8px;
        order: 3;
        padding: 0;
    }

    .dashboard-widget-wrapper .list-pagination .records-per-page {
        margin: 0 3px 0 0;
    }

    .dashboard-widget-wrapper .list-pagination .records-per-page > button {
        padding: 5px;
    }

    .dashboard-widget-wrapper .list-pagination .pagination a {
        padding: 5px 10px;
    }

    // Widget Meta Bar
    .meta-bar {
        margin-bottom: 8px;
        position: relative;
        z-index: 1;
    }

    .meta-bar .field-wrapper {margin-top:0px;}

    .meta-bar .filter-btn {
        float: right;
        margin-left: 10px;
    }

    .meta-bar > div[class*="col-"]:first-of-type,
    .meta-bar form > div[class*="col-"]:first-of-type {
        padding-left: 8px;
    }

    .meta-bar > div[class*="col-"].empty-input.select:first-of-type div.label,
    .meta-bar form > div[class*="col-"].empty-input.select:first-of-type div.label {
        left: 7px;
    }

    .meta-bar > div[class*="col-"]:last-of-type,
    .meta-bar form > div[class*="col-"]:last-of-type {
        padding-right: 8px;
    }

    // Column Selector
    .column-selector-dropdown.dropdown {

        &.open .dropdown-menu {
            display: flex;
        }

        .dropdown-menu {
            min-width: 360px;
            flex-wrap: wrap;

            .column-selection-item {
                width: 50%;
                padding: 0 8px;

                .field-wrapper {
                    margin: 0;
                    min-height: auto;
                }
            }

            .footer {
                border-top: 1px solid #e5e5e5;
                margin-top: 10px;
                padding: 10px 10px 5px 10px;
                display: flex;
                width: 100%;

                button:first-of-type {
                    margin-left: auto;
                }
            }
        }
    }


    // Filters Selector
    .dropdown.filter-btn .dropdown-menu {
        min-width: 200px;
    }

    .dropdown.filter-btn ul.filters {
        font-size: 12px;
        list-style: none;
        padding: 5px 0;
    }

    .dropdown.filter-btn ul.filters li {}

    .dropdown.filter-btn ul.filters li:not(:last-of-type) {}

    .dropdown.filter-btn ul.filters li .tag-button-wrapper {
        padding: 5px 10px 0 10px;
        display: inline-block;
        width: 100%;
    }

    .dropdown.filter-btn ul.filters li .tag-button {
        display: inline-block;
        margin-bottom: 2px;
        margin-right: 0;
        padding: 3px 5px;
        width:100%;
    }

    .dropdown.filter-btn ul.filters li .tag-button i.fa-times {
        margin-top:2px;
        float:right;
    }

    .dropdown.filter-btn ul.filters li .tag-button > span {
        margin-right:5px;
    }

    .dropdown.filter-btn ul.filters li .no-filters-indicator {
        padding: 10px 10px 0 10px;
        display: inline-block;
        width: 100%;
        text-align:center;
    }

    // Chart Widget
    .gridster-item .chart-widget .dashboard-widget-body > div {
        padding: 8px;
    }

    .gridster-item .chart-widget .chart-div {
        height: 100%;
        width: 100%;
    }

    .gridster-item .chart-widget .meta-bar {
        margin-bottom: 5px;
        padding-bottom: 5px;
    }

    .gridster-item .chart-widget .meta-bar + .chart-div {
        height: ~"calc(100% - 42px)";
    }

    .gridster-item .chart-widget .amChartsPeriodSelector div:first-of-type {
        display: none !important;
    }

    // Value/Number Widgets
    .gridster-item .value-widget .dashboard-widget-header {
        border-color: transparent;
        border-top-left-radius: @baseBorderRadius;
        border-top-right-radius: @baseBorderRadius;
        display: none;
        position: absolute;
        z-index: 10;
    }

    .gridster-item:hover .value-widget .dashboard-widget-header {
        display: block;
    }

    .gridster-item .value-widget .dashboard-widget-body > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 15%;
    }

    .gridster-item .value-widget .dashboard-widget-body .label-wrapper {
        height: 32px;
        position: relative;
        z-index: 2;
    }

    .gridster-item .value-widget .dashboard-widget-body .label-wrapper h3 {
        background: @white;
        bottom: 0;
        cursor: pointer;
        font-size: @mainFontSize;
        line-height: 16px;
        margin: -3px;
        padding: 3px;
        position: absolute;
        width: 100%;
    }

    .gridster-item .value-widget .dashboard-widget-body .label-wrapper h3 i {
        color: #aaa;
        display: none;
    }

    .gridster-item .value-widget:hover .dashboard-widget-body .label-wrapper {
        z-index: 3;
    }

    .gridster-item .value-widget .dashboard-widget-body a {
        font-size: 12px;
        margin: 6px 0 0 0;
    }

    .gridster-item .value-widget .dropdown a {
        text-transform: capitalize;
        margin: 0;
    }

    .gridster-item .value-widget .editable-wrap {
        position: absolute;
        top: -29px;
        white-space: normal;
        width: 100%;
    }

    .gridster-item .value-widget .editable-controls {
        display: block;
        margin: 0 -10px;
        width: ~"calc(100% + 20px)";
        height:20px;
    }

    .gridster-item .value-widget .editable-click::after { 
        margin-top: 0;
    }
    .gridster-item .value-widget .editable-wrap .editable-controls > input { 
        padding-right: 1px;
        margin-right: 100px;
    }

    .gridster-item .editable-wrap .editable-controls,
    .gridster-item .editable-wrap .editable-error {
        height: auto;
    }

    .gridster-item .editable-wrap .editable-controls > input {padding:1px 105px 1px 1px;
        font-size:10px;
        height:20px;
    }

    .gridster-item .editable-buttons {
        top:0px;
        height:17px;
        overflow:hidden;
        height:20px;
    }

    .gridster-item .editable-buttons button {
        position: relative;
        font-size: 10px;
        padding: 0px 10px;
        border-color:transparent;
        border-radius:0px;
    }
    .gridster-item .editable-buttons button + button {
        position: relative;
        font-size: 10px;
        padding: 0px 10px;
        border-color:transparent;
        border-radius:0px;
        margin-left: 0px;
    }

    .gridster-item .dashboard-widget-header .editable-save-btn {}

    .gridster-item .dashboard-widget-header .editable-cancel-btn {}

    .gridster-item .dashboard-widget-header .editable-cancel-btn:hover {}

    .gridster-item .value-widget .dashboard-widget-body .value {
        display: block;
        font-size: 40px;
        font-weight: 300;
        height: 40px;
        line-height: 1em;
        margin: 15px 0;
        width: 100%;
    }

    .gridster-item .value-widget .dashboard-widget-body .value-comparison-wrapper {
        color: @lightFontColor;
        font-size: 11px;
        min-height: ~"calc(1.42857143 * 2em)";
    }

    .gridster-item .value-widget .dashboard-widget-body .value-comparison-wrapper p {
        margin: 0;
    }

    .gridster-item .value-widget .dashboard-widget-body .value-comparison-wrapper .success i,
    .gridster-item .value-widget .dashboard-widget-body .value-comparison-wrapper .success strong {
        color: @success;
    }

    .gridster-item .value-widget .dashboard-widget-body .value-comparison-wrapper .failed i,
    .gridster-item .value-widget .dashboard-widget-body .value-comparison-wrapper .failed strong {
        color: @error;
    }

    .gridster-item .value-widget .dashboard-widget-body .value-comparison-wrapper .success strong,
    .gridster-item .value-widget .dashboard-widget-body .value-comparison-wrapper .failed strong {
        font-weight: 600;
    }

    .gridster-item .value-widget .dropdown { /*z-index: 0;*/ }

    .gridster-item .value-widget .dropdown-menu {
        min-width: auto;
    }

    .gridster-item .value-widget .dropdown-menu > li > a {
        background-color: transparent;
        margin: 0;
    }

    .gridster-item .value-widget .widget-edit-background {
        background: @white;
        border-radius: @baseBorderRadius;
        bottom: 0;
        left: 0;
        opacity: 0.9;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;
    }

    /* widget border colors */
    // uib-dropdown
    .dropdown .color-option {
        span {
            background: @white;
            border: 1px solid @white;
            display: inline-block;
            height: 1em;
            left: 2px;
            margin-right: 5px;
            position: relative;
            top: 2px;
            width: 1em;
        }

        &.selected:not(.none) span {
            box-shadow: 0 0 0 2px @orange;
        }

        &.red span {
            background: @red;
        }

        &.green span {
            background: @green;
        }

        &.blue span {
            background: @linkBlue;
        }

        &.orange span {
            background: @orange;
        }

        &.peach span {
            background: @peach;
        }

        &.charcoal span {
            background: @charcoal;
        }
    }

    // widget border
    .gridster-item.has-border {
        border-width: 3px 1px 1px 1px;
        padding-top: 0;

        &-red {
            border-top-color: @red;
        }

        &-green {
            border-top-color: @green;
        }

        &-blue {
            border-top-color: @linkBlue;
        }

        &-orange {
            border-top-color: @orange;
        }

        &-peach {
            border-top-color: @peach;
        }

        &-charcoal {
            border-top-color: @charcoal;
        }
    }

    /* stats */
    .email-stats-widget .meta-bar .filter-btn {
        margin-top: 12px;
    }

    .stats-group {
        border: 1px solid @tabGray;
        border-radius: @baseBorderRadius;
        margin: 8px 0 10px 0;
        overflow: hidden;
    }

    .stats-group .stat {
        border-right: 1px solid @tabGray;
        float: left;
        padding: 10px;
        position: relative;
    }

    .stats-group .stat:last-of-type {
        border-right: none;
    }

    .stats-group.stats-x4 .stat {
        width: 25%;
    }

    .stats-group.stats-x3 .stat {
        width: 33.3333%;
    }

    .stats-group .stat .stats-btns {
        display: block;
        height: 26px;
        position: absolute;
        right: 6px;
        top: 4px;
    }

    .stats-group .stat .stats-value {
        margin-bottom: 0;
        font-weight: 700;
    }

    .stats-group .stat .stats-value span {
        color: @lightFontColor;
        font-weight: 300;
    }

    .stats-group .stat .stats-label {
        text-transform: uppercase;
    }

    .stats-group .bootstrap-switch {
        margin: 0;
    }

    .stats-group .bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-on,
    .stats-group .bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-off,
    .stats-group .bootstrap-switch.bootstrap-switch-small .bootstrap-switch-label {
        line-height: 14px;
    }

    .stats-group .bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-on,
    .stats-group .bootstrap-switch.bootstrap-switch-small .bootstrap-switch-handle-off {
        font-weight: bold;
    }

    .gridster-item .chart-widget .dashboard-widget-body .stats-group + .chart-div {
        height: ~"calc(100% - 81px)";
    }

    .gridster-item .chart-widget .dashboard-widget-body .meta-bar + .stats-group + .chart-div {
        height: ~"calc(100% - 117px)";
    }

    .dashboard-widget-wrapper .busy-indicator-backdrop {
        height: 100%;
    }

    .dashboard-widget-wrapper .busy-indicator-default-spinner {
        height:15px;
        width:15px;
        top: 9px;
    }

    .dashboard-widget-wrapper .busy-indicator-default-text {
        font-size:11px;
        margin: 8px 12px 6px 39px;
    }

    .dashboard-widget-wrapper .busy-indicator-default-sign {
        padding-bottom: 2px;
        top: ~'calc(50% - 30px)';
    }

    .gridster .no-wigdets .initial-state {
        background: transparent;
        border:none;
    }

    /* responsive */
    @media (max-width: 1366px) {
    
        .gridster-item .dashboard-widget-header h2 {
            font-size: 13px;
        }
    
        .gridster-item .dashboard-widget-header .dashboard-widget-hover-options button {
            height: 32px;
        }

        .gridster-item .value-widget .dashboard-widget-body .label-wrapper h3 {
            font-size: 13px;
        }

        .gridster-item .value-widget .dashboard-widget-body .value {
            font-size: 32px;
            margin: 12px 0;
        }
        
        .email-stats-widget .meta-bar .filter-btn {
            margin-top: 13px;
        }

    }

    .gridster-item .initial-state,
    .gridster-item .empty-state {
        padding:30px 30px 15px 30px;
        min-height: 225px;
    }

}

body .themed.a3{
    
    .gridster-item {
        border:1px solid #ccc;
    }
   
    .gridster-loaded .gridster-item.has-border {
        border-top:2px;
        border-top-style: solid;
    }
  
    .gridster-loaded .gridster-item.has-border-red {
        border-top-color: #e64a4a;
    }
   
    .gridster-loaded .gridster-item.has-border-green {
        border-top-color: #5b9840;
    }
   
    .gridster-loaded .gridster-item.has-border-blue {
        border-top-color: #428bca;
    }
   
    .gridster-loaded .gridster-item.has-border-orange {
        border-top-color: @orange;
    }
   
    .gridster-loaded .gridster-item.has-border-peach {
        border-top-color: #f8cd95;
    }
   
    .gridster-loaded .gridster-item.has-border-charcoal {
        border-top-color: #333c48;
    }
   
    .editable-buttons button {
        border-radius: 0;
    }
}
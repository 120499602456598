@import 'a3_variables.less';
@import 'dark_variables.less';

.dropzone { 
    border-width: 1px;
    border-style: dashed;
    text-align: center;  
    cursor:pointer; 
    position:relative; 
}

.dropzone .progress {
    box-shadow:none; 
 }
   
.dropzone:hover {
    border-style:solid;
}


.dz-drag-hover {
    border-style: solid; 
}


.dropzone .progress { 
    position: absolute; 
    bottom: 0; 
    margin:0; 
    width:100%;
    height:8px;
}

.dropzone .progress:first-child { 
    bottom: 8px;
}

.dz-click-area { 
    width: 100%; 
    height: 100%; 
    position: absolute; 
    top: 0; 
    left: 0;
}

/* sizes */
.dropzone.thin { 
    min-height:50px; 
}

/* Hide some default stuff */
.dropzone .dz-message { 
    display:none; 
}

.dz-preview {
    display:none;
}

.dropzone > p { 
    margin: 5px; 
}

body .themed.silver {
    .dropzone { border: @orange 1px dashed; color: #666; background-color:#fff; text-align:center; background-position:center; background-repeat:no-repeat; }
    .dz-drag-hover {border-style: solid; border-color: darkseagreen; background-color:darkseagreen; color:green; color:white;}
    .dropzone .progress {box-shadow:none; background-color:#e7e9eb; }
}

body .themed.a3 {
    .dropzone { border: @orange 1px dashed; background-color:@white; color: #666; text-align:center; background-position:center; background-repeat:no-repeat; }

    .dz-drag-hover {border-style: solid; border-color: darkseagreen; background-color:darkseagreen; color:green; color:white;}
    .dropzone .progress {box-shadow:none; background-color:#e7e9eb; }
}

body .themed.dark {
    .dropzone { 
        border-color: @dark_dropzoneBorderColor; 
    }
    
    .dz-drag-hover {
        border-color: @dark_positive; 
        background-color:darkseagreen; 
        color: @dark_gray2;
    }
    
    .dropzone .progress {
        background-color: @dark_dropzoneProgressBarColor; 
    }
}
@import 'a3_variables.less';


/* ng-img-crop*/
.ng-img-crop { overflow: hidden; float:left; width:200px; height:200px; margin-right:20px; margin-left:20px; }

.user-profile-file-input { display: none; }

.profile-user-image { float:left; text-align:center; }
.profile-user-image label { background: transparent; color: #ffa400; border-color: transparent; box-shadow: none; padding: 20px 20px 8px 20px; font-size: 11px; font-weight: normal; line-height: 13px; border-width: 1px; border-style: solid; border-radius: 2px; outline: 0; cursor: pointer; text-decoration: none; display: inline-block; cursor: pointer; position: relative; }
.profile-user-image label img { display:block; height:200px; width:200px; }
.profile-user-image label button { position: absolute; top:10px; right:10px; display:none;}
.profile-user-image label:hover button { display: inline; }
.profile-user-info { float:left; min-width:400px; }
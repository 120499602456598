@import 'a3_variables.less';

/* table */
table { width:100%; border-spacing: 0; border-collapse: collapse; box-sizing: border-box;}

table thead tr th { white-space:nowrap; font-weight:normal;}

table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td { 
    padding: 5px 10px; 
    vertical-align: middle;
}

table > tbody + tbody { border:none; }

/* nested table */
table table { border-width: 0px; border-style:solid; }

/* table icons */
table i { margin-right: 4px; }
table tr > th i[class*='fa-sort'] { font-size:85%; }

/* table controls */
table button, table a.button { padding: 3px 15px; font-size:11px; margin:0; border-left-width:1px; border-left-style: solid; border-radius:0;}
table button:first-child, table .button:first-child { border-top-left-radius:2px; border-bottom-left-radius:2px; border-left:none;}
table button:last-child, table .button:last-child { border-top-right-radius:2px; border-bottom-right-radius:2px;}
table th button > i.fa,
table th a.button > i.fa,
table td button > i.fa,
table td a.button > i.fa,
table td span button > i.fa,
table td span a.button > i.fa { margin:0; }

table thead tr th button { box-shadow:none; font-size:inherit; padding:0; }

table td.fit-content,
table th.fit-content {
    white-space: nowrap;
    width: 1%;
    text-align: center;
}

table td.fit-content.text-left,
table th.fit-content.text-left {
    text-align: left;
}

table td.fit-content.text-right,
table th.fit-content.text-right {
    text-align: right;
}

table td.button-column {
    white-space: nowrap;
    width: 1%;
    font-size: 0;
}

table td.button-column > button:not(:last-of-type),
table td.button-column > a:not(:last-of-type) {
    margin-right: -4px;
}

.table-search-field, .table-search-field.no-label { 
    margin-top: 0;
    margin-right: 10px;
    min-width: 400px;
}

/* no records */
tr.noRecords { text-align: center; }
tr.noRecords span { margin-top: 20px; font-size: 18px; display: inline-block; }
tr.noRecords p { margin-bottom: 20px; display: block; }

.dropzone + table {margin-top:20px;}

.date-column { text-align: center; width:1px; white-space:nowrap;}
.money-column { text-align: center; width:1px; white-space:nowrap;}
.bond-number-column { text-align: center; width:1px; white-space:nowrap;}

// thumbnails
td.thumbnail-group {}
td.thumbnail-group span { display: inline-block; height: 30px; width: 30px; }
td.thumbnail-group span img { display: inline; width: 100%; }
td.carriers-thumbnails img { opacity: 0.15; }
td.carriers-thumbnails img.on { opacity: 0.95; }

//columns
table .check-column {text-align:center; width:100px;}
table .check-column .checkbox { min-height: unset; }
table .check-column .checkbox input { float: unset; } 

// colapsable rows
table tr.clickable-table-row td { cursor: pointer; }
table tr.table-details-row > tr:last-of-type td { border-width: 0; }
table tr.table-details-row .bond-grid-details-row {
    border-width: 1px 0 0 0; 
    border-style: solid; 
    width: 100%; 
    display: inline-block; 
    padding: 5px 0;
}

table .logo-cell { padding:0 0 0 5px; }
table .logo-cell img { height:35px; width:35px; padding:3px; background-color:white; border:1px solid #ccc;}

@media (max-width: 1366px) {
    table > thead > tr > th,
    table > tbody > tr > th,
    table > tfoot > tr > th,
    table > thead > tr > td,
    table > tbody > tr > td,
    table > tfoot > tr > td { padding: 8px; }
}

table.v-align-middle td,
table.v-align-middle th {
    vertical-align: middle !important;
}

table tr.cursor-pointer { cursor: pointer; }
table td.grab-handle { cursor: move; padding-right: 0; width: 4px; }
table .table-sortable-fallback {display:none;}
table .sortable-chosen td { background-color: darkseagreen !important; }

table th.expand-indicator-column, table td.expand-indicator-column {
    width: 10px;
    text-align: center;
}

table th.row-select-column, table td.row-select-column {
    width: 10px;
    text-align: center;
    vertical-align: middle;
}

table th.row-select-column .field-wrapper, table td.row-select-column .field-wrapper {
    min-height: auto;
    margin: 0;
}

table th.row-select-column .field-wrapper input, 
table td.row-select-column .field-wrapper input {
    margin: 0;
}


table:not(.group-by-tbody) > tbody > tr:not(.table-details-row):not(:last-of-type) > td {
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

table.group-by-tbody > tbody > tr > td {
    border-bottom-width: 0;
}

table.group-by-tbody > tbody {
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

table tr.no-row-border > td {
    border-bottom-color: transparent !important;
}

/* No stripes */
table.no-stripe > tr > td,
table.no-stripe > tbody > tr > td {
    background-color: transparent !important;
}

.email-preview > img {
    display: none;
}

table.form-table tr td .field-wrapper {
    margin: 0;
}

table.form-table tr td:first-of-type {
    width: 1%;
}

table.form-table tr td {
    background: transparent !important;
    vertical-align: text-bottom;
    padding: .5rem 1rem;
    white-space: nowrap;
}

body .themed.a3 {
    /* Nested table */
    table table {
        border-color: @tabGray;
    }

    table thead tr th {
        background-color: @gray;
        border-bottom-color: transparent;
    }

    table > tbody > tr > td {
        border-bottom-color: transparent;
    }

    table.group-by-tbody > tbody {
        border-bottom-color:@tabGray; 
    }

    table thead tr th button {
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
        color: #333;
    }

    table thead tr th button:hover {
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
        color: #333;
    }


    table button, table a.button {
        background: @orange;
        color: @charcoal;
        border-left-color: @gray;
        font-weight: 600;
    }

    /* table stripes */
    table:not(.manual-stripe) > tbody > tr:nth-child(odd) > td, 
    table > tbody > tr:nth-child(odd) > th {
        background-color: #fafafa;
    }

    table:not(.manual-stripe) > tbody > tr:nth-child(even) > td, 
    table > tbody > tr:nth-child(even) > th {
        background-color: @gray;
    }

    table.manual-stripe > tbody > tr > td {
        background-color: @gray;
    }

    table.manual-stripe > tbody > tr.alt > td {
        background-color: #fafafa;
    }

    // tbody-grouped stripes
    table.group-by-tbody > tbody:nth-of-type(even) > tr > td
    {
        background-color: @gray;
    }

    table.group-by-tbody > tbody:nth-of-type(odd) > tr > td {
        background-color: #fafafa;
    }

    tr.noRecords span {
        text-shadow: 1px 1px 1px #ccc;
        color: #777;
    }

    //colapsable rows
    table tr.table-details-row .bond-grid-details-row {
        border-color: #fafafa;
    }

    table tr.clickable-table-row:hover td.clickable-table-cell i {
        color: @orange;
    }

    table tr.alt.table-details-row .bond-grid-details-row {
        border-color: @gray;
    }

    .hide-overflow > span > span > span {
      display: inline-block;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 800px;
    }

    table .no-space {
        width: 1%;
        white-space: nowrap;
    }

    table tr td.table-row-status-critical {
        border-left: 1rem solid @red;
    }
    
    table tr td.table-row-status-warning {
        border-left: 1rem solid @warningOrange;
    }
    
    table tr td.table-row-status-attention {
        border-left: 1rem solid @orange;
    }

    .email-preview { 
        border: 1px solid #ccc; 
    }
    
    .email-preview table {
        border:none; background: white !important; 
    }
    
    .email-preview table tr > td {
        background: white !important; 
    }
}
@import 'a3_variables.less';
@import 'dark_variables.less';

body .themed.dark {

    .modal-content {
        background-color: @dark_modalBackgroundColor; 
        box-shadow: @dark_modalBoxShadow;
    }
    
    .modal-backdrop { 
        background: @dark_modalBackdropColor;
        opacity: @dark_modalBackdropOpacity;
    }
    
    .modal header > button {
        color: @dark_modalHeaderButtonFontColor;
        background: @dark_modalHeaderButtonBackgroundColor; 
        border-color: @dark_modalHeaderButtonBorderColor 
    }
    
    .modal header > button:hover {
        color: @dark_modalHeaderButtonFontColor; 
        background: @dark_modalHeaderButtonBackgroundColor; 
        border-color: @dark_modalHeaderButtonBorderColorHover; 
    }
}
@import 'a3_variables.less';

body .themed.silver {
    /* list pagination */
    .list-pagination { margin-bottom:20px; }
    .list-pagination:after { content:""; display:table; clear:both; }

    .list-pagination [class^="col-"] { padding:0; }
    .list-pagination [class^="col-"]:first-of-type .dropdown > a { padding-left:0; }
    .list-pagination [class^="col-"]:last-of-type { text-align:right; }
    .list-pagination .pagination { margin:0; }
    .list-pagination .pagination  a { border:none; padding: 8px 12px; }

    .list-pagination .records-per-page { margin: 0 10px 0 0; }
    .list-pagination .records-per-page > button {  padding: 8px; font-weight: normal; }
    .list-pagination .records-per-page > button i { margin-right: 0; }
    .list-pagination .records-per-page .dropdown-menu { min-width: 0; }

    .list-pagination .pagination  a { border:none; color: @lightFontColor; }
    .list-pagination .pagination a:hover {color: @lightFontColor;}
    .list-pagination .pagination > .active > a { background: #627789; color: white; }
    .list-pagination .pagination > .active > a:hover { background: #627789; color: white; }
    .list-pagination .records-per-page > button { background-color: @white; color: @lightFontColor;  }  
    
    .pagination > li:last-child > a, .pagination > li:last-child > span { border-top-right-radius: 2px; border-bottom-right-radius: 2px; }
    .pagination > li:first-child > a, .pagination > li:first-child > span { border-top-left-radius: 2px; border-bottom-left-radius: 2px; }
}
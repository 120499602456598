@import 'a3_variables.less';

.ta-toolbar { 
    background-color: #F0F0F0; 
    padding: 10px 10px 5px; 
    margin-left: 0; 
    border: 1px solid #EEE; 

    .form-control { 
        border-right:none; 
        border-bottom: none; 
        border-left:none; 
        height:auto; 
        border-radius:0; 
    }
}

.ta-root {
    margin-top:10px; 
    display: block; 
    border: 1px solid #ccc;
    
    .ta-bind:focus { 
        outline: none; 
    }

    .btn-group {
        margin: 0 5px 5px 0;
    }

    button, .btn {
        padding: 3px 5px; 
        font-size:14px; 
    }

    .btn:active, 
    .btn:hover, 
    .btn:focus, 
    .btn-default.disabled,
    .btn-default[disabled],
    fieldset[disabled] .btn-default,
    .btn-default.disabled:hover,
    .btn-default[disabled]:hover,
    fieldset[disabled] .btn-default:hover,
    .btn-default.disabled:focus,
    .btn-default[disabled]:focus,
    fieldset[disabled] .btn-default:focus, 
    .btn-default.disabled:active,
    .btn-default[disabled]:active,
    fieldset[disabled] .btn-default:active, 
    .btn-default.disabled.active,
    .btn-default[disabled].active,
    fieldset[disabled] .btn-default.active {
        
        border-color: transparent; 
        background: #ffa400; 
        color: #fff; 
        box-shadow: 0 1px 1px rgba(0,0,0,0.18);
    }

    .btn.active, 
    button.active { 
        box-shadow: 0 1px 1px rgba(0,0,0,0.18); 
    }
       
    &.focussed > .ta-scroll-window.form-control { 
        border: none; 
        box-shadow:none; 
    }

    &.focussed {
        border: 1px solid @orange; 
    }
}

.ta-hidden-input { 
    display: none; 
}

i.disabled { 
    opacity: .25; 
}

.ta-bind table tr td { 
    background-color: transparent !important; 
}

.ta-bind { 
    text-align: left !important; 
}

.ta-bind table table { 
    border-width:0px !important; 
}
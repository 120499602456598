@import 'a3_variables.less';
@import "dark_variables.less";

body .themed.dark {
    .side-menu {
        background-color: @dark_secondaryBackgroundColor; 
        border-right: 1px solid @dark_navBorderColor;
        box-shadow: @dark_navBoxShadow;
    }

    .side-menu  ul > li:last-of-type { border-bottom:1px solid @dark_navBorderColor; }
    .side-menu  ul > li.current { background: @dark_gray3; }

    .side-menu  ul > li.current > a { text-decoration:none; border-right:6px solid @dark_orange; }
    .side-menu-collapsed .side-menu ul > li.current > a { padding-left:5px; }

    .side-menu ul li { border-top:1px solid @dark_navBorderColor; }
    .side-menu ul li a { color:@dark_primaryFontColor; padding-top:10px;}
    .side-menu ul li a:hover { text-decoration:none; color:@dark_orange; }

    .side-menu ul li ul li { border-top:1px solid @dark_navBorderColor; }
    .side-menu ul li ul li a { padding-left:30px; }

    .side-menu ul li.current ul li.current a { border-right:6px solid @dark_critical; }
    .side-menu ul li ul.a-sub-menu { border-top: 1px solid @dark_navBorderColor; }

    .side-menu .search:before { color:@dark_orange; }
    .side-menu .search input { background-color:@dark_secondaryBackgroundColor; color:@dark_primaryFontColor; }

    .sub-menu-slide { background-color: @dark_secondaryBackgroundColor; color: @dark_primaryFontColor; }
    .sub-menu-slide header { border-bottom-color: @dark_navBorderColor; background-color: @dark_darkBackground; color: @dark_primaryFontColor; }
    .sub-menu-slide .sub-menu li { border-bottom-color: @dark_navBorderColor; }
    .sub-menu-slide .sub-menu li a { color: @dark_primaryFontColor; padding: 9px 20px 10px 15px;}
    .sub-menu-slide .sub-menu li a:hover, .sub-menu-slide .sub-menu li a:focus { color: @dark_orange; }
}

body .themed.silver.side-menu-collapsed .side-menu ul > li.current > a { padding-left:6px; }
body .themed.silver.side-menu-collapsed .side-menu ul li a { padding: 8px 0 7px 0;}
@import 'a3_variables.less';

/* layout */
form { text-align: right; }

/* Buttons */
form > button, form > input[type="button"], form > input[type="submit"] {margin-top: 10px;}

.label-left {line-height: 36px; text-align: right;}

img.smallLogo {width:75px; height:75px;}

i.favorite {cursor:pointer; color: @orange;}



.field-flex-box {display: flex; }
.field-flex-box div { -ms-flex:1; flex: 1;}
.field-flex-box button {  height: 24px; -ms-flex:.15; flex: .15; margin-top:25px; border-top-left-radius: 0; border-bottom-left-radius: 0; border-left-width: 1px; border-left-style:solid; border-left-color: white; padding:4px 20px 8px 20px; }
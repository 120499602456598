@import 'a3_variables.less';

/* login container */
body > .main-view > .a-container { width:100%; height:100%; position:relative; overflow:hidden; }

body .main-view .a-container {
    /* login background */

    .a-background { width:100%; height:100%; background-color:#333c48; }
    
    /* login message*/

    .a-message { width:480px; margin-bottom:165px; margin-left:-240px; padding:6px; position:absolute; left:50%; bottom:55%; font-size:14px; font-weight:bold; text-align:center; border-radius:2px; background:@orange; color:#fff; }

    /* login box */
    .a-box { width:400px; /*height:166px;*/ margin-top:-145px; margin-left:-200px; padding:20px; position:absolute; top:45%; left:50%; border-radius:2px; background:@translucentBlue;  }
    .a-box p { margin-bottom:17px; text-align:center; font-weight:bold; font-size:17px; color:#fff; }
    .a-box img { width:75px; margin:0 auto 20px; display:block; }

    .a-box input[type=email], 
    .a-box input[type=password],
    .a-box input[type=email]:focus, 
    .a-box input[type=password]:focus 
     { width:100%; margin-bottom:10px; box-sizing:border-box; border-width:2px; border-style: solid; border-color: #fff; border-radius:2px; background:#fff; }

    .a-box input[type=submit] { width:100%; padding:10px; margin-bottom:10px; box-sizing:border-box; font-size:15px !important; border:none; background:linear-gradient(#ffd28e, #ffbb56); color:#fff; }
    .a-box input[type=button] { padding-right:0; padding-top:0; float:right; line-height:10px; font-size:12px !important; border:none; background:none; color:#ffa403; }

    .a-box .button-container { width:100%; }
    .a-box .button-container .btn { width:49%; float:left; }
    .a-box .button-container input { padding:6px; font-size:15px !important; line-height:21px; border:none; border-radius:2px; background:linear-gradient(#ffd28e, #ffbb56); color:#fff; }
    .a-box .button-container input:first-of-type { margin-right:1%; }
    .a-box .button-container input:last-of-type { margin-left:1%; }

    .a-box .btn {box-shadow:none;}

    .a-box input.error { border:2px solid @red; }

    .a-box.user-selection {
        color: #D3D7D9;
        width: 600px;
        margin-left: -300px;
        top: 0;
        margin-top: 0;
        padding-top: 4rem;
        bottom: 0;
        display: flex;
        flex-direction: column;
    }

    .a-box.user-selection h3 {
        border-bottom: 1px solid;
        padding-bottom: 2rem;
    }

    .a-box.user-selection .user-selection-choices {
        overflow: auto;
    }

    .a-box.user-selection .user-choice {
        cursor: pointer;
        padding: 1rem;
    }
    
    .a-box.user-selection .user-choice:hover {
        background-color: #3A4552;
    }
    
    .a-box.user-selection .user-choice img {
        width: 40px;
        height: 40px;
    }

}

/* footer */
.a-footer { width:100%; position:absolute; bottom:0; background-color:@charcoal; }
.a-footer ul { margin:15px 0; text-align:center; list-style:none; }
.a-footer ul li { margin-right:50px; display:inline-block; color:#fff; }


/* Terms and Conditions */
.terms-and-conditions h1 { text-align: center; }
.terms-and-conditions h2 { text-align: center;margin-top: 30px;}
.terms-and-conditions h2:first-of-type { margin-top: 0; }
.terms-and-conditions h3 { font-weight: bold; margin-bottom: 0; }
.terms-and-conditions dt { width: 50px; float: left; margin-left: 25px; }
.terms-and-conditions dd { float: left; margin: 0; display: block;width: ~'calc(100% - 75px)'; }
@import 'dark_variables.less';

body .themed.dark {
    .list-pagination .pagination a { 
        border: none; 
        color: @dark_paginationButtonFontColor;  
        background-color: @dark_paginationButtonBackgroundColor;
        border-color: @dark_paginationButtonBorderColor;
    }
    
    .list-pagination .pagination a:hover {
        color: @dark_paginationButtonFontColor_hover;
        background-color: @dark_paginationButtonBackgroundColor_hover;
        border-color: @dark_paginationButtonBorderColor_hover;
    }
    
    .list-pagination .pagination > .active > a { 
        background: @dark_paginationButtonBackgroundColor_active;
        color: @dark_paginationButtonFontColor_active;
    }
    
    .list-pagination .pagination > .active > a:hover { 
        color: @dark_paginationButtonFontColor_active_hover
    }

    .list-pagination .records-per-page > button { 
        background-color: @dark_primaryButtonBackgroundColor; 
        border-color: @dark_primaryButtonBorderColor;
        color: @dark_primaryButtonTextColor; 
    }

    .list-pagination .records-per-page > button:hover { 
        background-color: @dark_primaryButtonBackgroundHoverColor; 
        border-color: @dark_primaryButtonBorderHoverColor;
        color: @dark_primaryButtonTextHoverColor; 
    }
}
@import 'a3_variables.less';
@import 'silver_variables.less';

body .themed.silver {
    /* text inputs &*/
    input[type=text],       input[type=email],  input[type=url],    input[type=number], 
    input[type=password],   textarea,           select,             .ui-select-match 
    { border-color: #ddd; background-color: #fff; }

    input[type=text].white,       input[type=email].white,  input[type=url].white,    input[type=number].white, 
    input[type=password].white,   textarea.white,           select.white,             .ui-select-match.white 
    { background-color: @white; }

    /* input outline */
    input:not([type=button]):not([type=radio]):not([type=checkbox]):not([type=submit]):focus, textarea:focus, select:focus { border-color: #627789; }
    input:disabled, select:disabled, select:disabled, textarea:disabled { background-color: #fff; }

    /* selects */
    select.unselected { color: @placeholderTextColor; }
    select.unselected option { color:@primaryFontColor; }
    select option[disabled] { color: @placeholderTextColor; }

    /* field-wrapper */
    .field-wrapper div.label { color:#333; }
    .field-wrapper .validation-indicator {color: red; margin-top: 0px; vertical-align: bottom; display: inline-block; line-height: 8px;  }
    .field-wrapper input:not([type=checkbox]):not([type=radio]), .field-wrapper select { background-color:@white; }

    /* empty-input */
    .empty-input:not(.search) input:not([type=checkbox]):not([type=radio]),
    .empty-input select,
    .empty-input textarea { background: @white; }
    .empty-input:not(.search) input:not(:focus),
    .empty-input select:not(:focus),
    .empty-input textarea:not(:focus) { border-color: @tabGray;}    

    // empty-input label
    .field-wrapper.empty-input.select div.label { }

    // disabled inputs
    .empty-input input:not([type=checkbox]):not([type=radio]):disabled, .empty-input select:disabled, .empty-input textarea:disabled { background: #fff; border-color: @gray; }
    .field-wrapper.empty-input.select.disabled select:disabled,
    .field-wrapper select:disabled { border-color: @gray; }
    .field-wrapper.empty-input .validation-indicator { color: red; margin-top: 0px; vertical-align: bottom; display: inline-block; line-height: 8px; }

    .inline-label { font-weight: normal; }

    /* validation */
    // these are the classes for standard agnular validation and angular agility validation
    // .ng-invalid - angular adds when not valid field
    // .ng-dirty - angular adds when field has been changed
    // .aa-had-focus - angular agility adds on blur
    // .aa-invalid-attempt - angular agility adds when form submitted but was invalid
    // had focus && invalid                         field dirty && invalid                      form was submitted && invalid
    input[type=text].ng-invalid.aa-had-focus,       input[type=text].ng-invalid.ng-dirty,       input[type=text].ng-invalid.aa-invalid-attempt, 
    input[type=email].ng-invalid.aa-had-focus,      input[type=email].ng-invalid.ng-dirty,      input[type=email].ng-invalid.aa-invalid-attempt,   
    input[type=url].ng-invalid.aa-had-focus,        input[type=url].ng-invalid.ng-dirty,        input[type=url].ng-invalid.aa-invalid-attempt,  
    input[type=number].ng-invalid.aa-had-focus,     input[type=number].ng-invalid.ng-dirty,     input[type=number].ng-invalid.aa-invalid-attempt,  
    input[type=password].ng-invalid.aa-had-focus,   input[type=password].ng-invalid.ng-dirty,   input[type=password].ng-invalid.aa-invalid-attempt,
    textarea.ng-invalid.aa-had-focus,               textarea.ng-invalid.ng-dirty,               textarea.ng-invalid.aa-invalid-attempt,
    select.ng-invalid.aa-had-focus,                 select.ng-invalid.ng-dirty,                 select.ng-invalid.aa-invalid-attempt,
    .ui-select-match.ng-invalid.aa-had-focus,       .ui-select-match.ng-invalid.ng-dirty,       .ui-select-match.ng-invalid.aa-invalid-attempt
    { border-color: @warningOrange }


    .validation-error { color:@warningOrange; }

    /* validation alert notification */
    .validation-notification .alert-danger { color: @gray; background-color: @charcoal; border-color: @warningOrange; box-shadow: 2px 2px 2px @charcoal; }
    .validation-notification ul li > a {  color: @orange;  }


    /* search boxes */
    .search > div > i.fa-search { background-color: transparent; }

    /* search box colors */
    .search.white > div > input:not([type=button]):not([type=submit]) { background-color:@white; border-color: #ddd; box-shadow:none; }
    .search.white > div > input:not([type=button]):not([type=submit]):focus { border-color: #627789; }
    .search.white > div > i { color: #ddd; }
    .search .dropdown button {background: transparent; color: #627789; }
    .search .dropdown button:hover { background: transparent; color: #627789; }


    /* Colors */
    input.white:not([type=button]):not([type=submit]) {background-color: @white; }
    input.black:not([type=button]):not([type=submit]) {background-color: @charcoal; color: @gray; }



    /* inline edit inputs */
    .boundGridInlineEdit input { background: @white; }

    /* ==== editable-form ==== */

    .editable-wrap .editable-controls > input { background: @white;  } 



    /* ==== element ==== */

    .editable-click::after { color: #aaa; }
    .editable-click:hover { background: lightyellow; }
    .editable-empty, 
    .editable-empty:hover, 
    .editable-empty:focus,
    a.editable-empty, 
    a.editable-empty:hover, 
    a.editable-empty:focus { color: #DD1144; } 

    .bootstrap-switch-wrapper label {font-weight:normal;}
}